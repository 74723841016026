@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Poppins', sans-serif !important;
}

.intialPos {
  position: initial !important;
}

.container {
  max-width: 1640px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.dash-container {
  max-width: 1832px;
  margin: 0 auto;

}

.border-header {
  background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 52%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  height: 2px;
}

.shadow-box {
  box-shadow: 0px 0px 10px 4px #B99237;
}

.react-responsive-modal-modal {
  border-radius: 20px !important;
  background-color: #FFFFFF !important;
}

.form-wrap .inner>p.text-left{
  text-align: left;
}

/* .react-responsive-modal-modal{
  width: px !important;
} */
@media (max-width: 520px) {

  .react-responsive-modal-modal {
    width: 305px !important;
  }
}

.dropdown-doc {
  position: absolute;
  bottom: 5em;
}

@media (max-width: 1920px) {

  .dropdown-doc {
    position: relative;
  bottom: auto;
  }

}

.dropdown-doc-expanded {
  position: relative !important;
  bottom: auto !important;
}


/* custom scroll bar */
/* width */
.customScroll ::-webkit-scrollbar, .list-wrapper::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.customScroll ::-webkit-scrollbar-track, .list-wrapper::-webkit-scrollbar-track {
  background: #2B2B2B80;
}

/* Handle */
.customScroll ::-webkit-scrollbar-thumb, .list-wrapper::-webkit-scrollbar-thumb {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.list-wrapper::-webkit-scrollbar-track {
  background: rgba(74, 74, 74, 0.5);
}
.dropdown {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
  background-color: white;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #E5E8EC;
  display: none;
}



.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91A5BE;
  transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91A5BE;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.react-responsive-modal-closeButton {
  left: 14px !important;
}


@media(max-width:991px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

select {
  display: block;
  width: 100%;
  font-size: 1em;
  padding: 0.8rem 0.5rem;
  /** for the dropdown indicator */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.main-body {
  min-height: 100vh;
  padding-bottom: 20px;
  background: #161D23;
}

.sidebar {
  background: #1D2833;
  /* border: 1px solid #55cbc6; */
}

.sidebar>div>ul>li a>div>div {
  border-radius: 5px;
  border: 1px solid transparent !important;
}

.sidebar>div>ul>li ul li a>div>div>img {
  object-fit: contain;
}

.sidebar>div>ul>li>a:hover>div>div,
.sidebar>div>ul>li>a>div>div:not(.false) {
  background: rgba(255, 255, 255, 0.05);
  /* border: 1px solid #55cbc6 !important; */
}


.sidebar>div>ul>li ul li a>div>div>img.fill {
  display: none;
}

.sidebar>div>ul>li>a>div>div img {
  object-fit: contain;
}

.sidebar>div>ul>li ul li a:hover>div>div img,
.sidebar>div>ul>li ul li a>div>div:not(.false) img {
  background: #fff;
  border-radius: 50%;
}

/* .sidebar>div>ul>li ul li a:hover>div>div img,
.sidebar>div>ul>li ul li a>div>div:not(.false) img {
  display: none;
} */

/* .sidebar>div>ul>li ul li a:hover>div>div img.fill,
.sidebar>div>ul>li ul li a>div>div:not(.false) img.fill {
  display: inline-block;
} */

.buy-vip-nft{
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 25px;
    transition: all ease-in-out 0.2s;
    margin-right: 30px;
    margin-left: auto;
}

.microeconomy-wrapper {
  padding: 0 50px;
}

.microeconomy-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.microeconomy-head .logo-wrap {
  display: flex;
  align-items: center;
}

.microeconomy-head .logo-wrap img {
  width: 34px;
}

.microeconomy-head .logo-wrap span {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  display: inline-block;
  padding-left: 10px;
}

.buy-sell-wrap {
  display: flex;
  justify-content: space-between;
}

.buy-sell-wrap button {
  width: 48% !important;
  background: transparent !important;
  border: 1px solid #55cbc6 !important;
}

.light .buy-sell-wrap button {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.buy-sell-wrap button.active {
  width: 48% !important;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
}

.light .buy-sell-wrap button.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  background-clip: auto !important;
  -webkit-background-clip: auto !important;
  -webkit-text-fill-color: aliceblue !important;
}

.microeconomy-head .btn-wrap {
  display: flex;
  align-items: center;
}

.microeconomy-head .btn-wrap .select-wrap {
  position: relative;
}

.microeconomy-head .btn-wrap .select-wrap {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#launcher{
  right: 90px !important;
}

.balance-wrap{
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.balance-wrap .balance{
  font-weight: 500 !important;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.balance-wrap .show-hide{
  margin-left: 12px;
  position: relative;
}

.balance-wrap .show-hide:before{
  position: absolute;
  content: '';
  width: 2px;
  height: 22px;
  transform: rotate(35deg);
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  top: -1px;
}

.balance-wrap.hide-balance .balance{
  filter: blur(4px);
}

.balance-wrap.hide-balance .show-hide:before{
  display: none;
}

.microeconomy-head .btn-wrap .select-wrap .selected-option {
  width: 94px;
  padding-right: 18px;
  background-image: url("./images/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 100% 46%;
  background-size: 10px;
  justify-content: flex-end;
}

.microeconomy-head .btn-wrap .select-wrap .options option,
.microeconomy-head .btn-wrap .select-wrap .selected-option {
  display: flex;
  align-items: center;
}

.microeconomy-head .btn-wrap .select-wrap .options .option,
.microeconomy-head .btn-wrap .select-wrap .selected-option {
  display: flex;
  align-items: center;
}

.microeconomy-head .btn-wrap .select-wrap .options .option img,
.microeconomy-head .btn-wrap .select-wrap .selected-option img {
  margin-right: 11px;
  width: 18px;
  margin-top: -2px;
}

.microeconomy-head .btn-wrap .select-wrap .options {
  position: absolute;
  border-radius: 5px;
  border: 1px solid rgba(29, 40, 51, 0.50);
  width: 100%;
  top: 34px;
  background: #161D23;
  z-index: 1;
}

.microeconomy-head .btn-wrap .select-wrap .options .option {
  padding: 3px 6px;
}

.microeconomy-head .btn-wrap .select-wrap .options .option:hover {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.microeconomy-head .btn-wrap select {
  width: 112px;
}

.microeconomy-head .btn-wrap button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.50);  
  border-radius: 5px;
  padding: 14px 21px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  line-height: 1;
  margin-left: 14px;
  transition: all ease-in-out 0.2s;
  padding-left: 40px;
  position: relative;
}

.microeconomy-head .btn-wrap button:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 50%;
  left: 12px;
  top: 12px;
}

.microeconomy-head .btn-wrap button:after {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.microeconomy-head .btn-wrap button span {
  font-weight: 300;
  border-left: 2px solid #fff;
  padding-left: 10px;
  margin-left: 10px;
}

.microeconomy-head .btn-wrap button img {
  margin-right: 12px;
}

.microeconomy-tool {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 60px;
}

.circularity-dao .microeconomy-tool {
  max-width: 100%;
}

.microeconomy-wrapper h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.microeconomy-modal {
  display: none;
}

.microeconomy-wrapper h2 button {
  margin-left: 20px;
  position: relative;
  top: 2px;
  transition: all ease-in-out 0.2s;
  background: transparent;
  padding: 0;
  width: auto;
}

.microeconomy-tool .inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.microeconomy-tool .inner .box {
  width: 32.4%;
  background: #1D2833;
  /* border: 0.7px solid #55cbc6; */
  border-radius: 5px;
  padding: 40px 15px 30px;
  text-align: center;
  margin-bottom: 15px;
}

.microeconomy-tool .inner .box img {
  margin: 0 auto 20px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}

.microeconomy-tool .inner .box h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  max-width: 160px;
  margin: 0 auto;
}

/* Microeconomy Modal */

.react-responsive-modal-modal.microeconomy-modal {
  border-radius: 0 !important;
  /* border: 1px solid #55cbc6 !important; */
  background-color: #272A33 !important;
  padding: 50px 20px !important;
  width: 612px !important;
  max-width: calc(100% - 2.4rem);
}

.react-responsive-modal-modal.microeconomy-modal.vesting-modal{
  width: 567px !important;
}

.react-responsive-modal-modal.microeconomy-modal.light,
.react-responsive-modal-modal.microeconomy-modal.steps-modal.light {
  background-color: #ffffff !important;
  background: #fff !important;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal.light {
  /* border: 1px solid #55cbc6 !important; */
}

.react-responsive-modal-modal.microeconomy-modal.stake-unstake-modal {
  width: 540px !important;
}

.microeconomy-modal button:last-child {
  display: none;
}

.microeconomy-modal.vesting-modal .vesting-btn{
  display: block;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body {
  text-align: center;
  max-width: 513px;
  margin: 0 auto;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body img {
  margin: 0 auto 8px;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.react-responsive-modal-modal.microeconomy-modal.light .modal-body h3,
.light.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body i,
.react-responsive-modal-modal.microeconomy-modal.light h4,
.light .ilo-detail-tabs-wrapper .nav-tabs .nav-item .nav-link,
.react-responsive-modal-modal.microeconomy-modal.add-liquidity.light .modal-body .input-wrapper p,
.react-responsive-modal-modal.microeconomy-modal.add-liquidity.light .form-wrap .label span,
.light .action-content h2,
.light.microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box p,
.light .microeconomy-head .btn-wrap .select-wrap,
.light .form-wrap .form-group label{
  color: #000000;
}

.light .ilo-detail-tabs-wrapper .nav-tabs .nav-link.active {
  color: #fff;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  color: #A9AAAD;
  margin-bottom: 5px;
}

.react-responsive-modal-modal.microeconomy-modal.execute-proposal-modal.dao .modal-body h3{
 margin-bottom: 30px;
}

.react-responsive-modal-modal.microeconomy-modal.execute-proposal-modal.dao .modal-body p{
  text-align: center;
  margin-bottom: 30px;
}

.form-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.form-wrap .inner {
  width: 568px;
  max-width: 100%;
}

.form-wrap .inner>p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #A9AAAD;
  margin-top: 15px;
}

.form-wrap .inner>p.left{
  text-align: left;
}

.form-wrap .inner .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-wrap .inner .head span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #A9AAAD;
  display: inline-block;
  margin-bottom: 20px;
}

.form-wrap.min {
  min-height: calc(100vh - 137px);
}

.form-wrap form {
  background: #1D2833;
  border-radius: 5px;
  padding: 48px;
}

.form-wrap form .form-group, .form-wrap .form-group {
  position: relative;
  margin-bottom: 15px;
}
.form-wrap .form-group label{
  color: rgba(255,255,255,0.8);
  margin-bottom: 4px;
  display: block;
  font-size: 15px;
}
.form-wrap form input,
.form-wrap form textarea,
.form-wrap form select,
.form-wrap input,
.form-wrap textarea,
.form-wrap select {
  background: #3C3F47;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 20px 35px;
  height: 70px;
  width: 100%;
}

.form-wrap form input:focus,
.form-wrap form textarea:focus,
.swap .form-group .input-group input,
.form-wrap form select:focus,
.form-wrap input:focus,
.form-wrap textarea:focus,
.form-wrap select:focus {
  border: 0.7px solid #55cbc6;
  --tw-ring-color: none;
}
.form-wrap form select{
  appearance: none;
  background-image: url("./images/select-arrow.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 24px;
}
.form-wrap form .toggle-wrap {
  width: 100%;
}

.form-wrap form .toggle-wrap label {
  justify-content: flex-start;
}

.form-wrap form .toggle-wrap label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  margin: 0;
  color: #A9AAAD;
  font-size: 14px;
}

.form-wrap form .toggle-wrap .check {
  position: relative;
  display: block;
  width: 36px;
  height: 20px;
  background: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 50px;
  overflow: hidden;
  transition: ease-in 0.5s;
  margin: 0 5px;
}

.form-wrap form .toggle-wrap input:checked[type="checkbox"]~.check {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}
.form-wrap form > span{
  color: #A9AAAD;
  font-size: 14px;
  display: block;
  margin-bottom: 12px;
}
.form-wrap form .toggle-wrap .check:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 4px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.5s;
}
.form-wrap form .input-wrap{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.form-wrap form .input-wrap .form-group{
  width: 48.5%;
}
.form-wrap form .toggle-wrap input:checked[type="checkbox"]~.check:before {
  transform: translateX(13px);
  background: #fff;
}

.form-wrap form .toggle-wrap input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  visibility: hidden;
  display: none;
}

.form-wrap form textarea {
  height: 200px;
}

.form-wrap form .filewrap, .form-wrap .filewrap {
  position: relative;
  background-color: #3C3F47;
  border: 0;
  border-radius: 5px;
  height: 70px;
  padding: 20px 35px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 15px;
  cursor: pointer;
}
.form-wrap form > label{
  color: #fff;
  font-weight: 500;
  margin-bottom: 12px;
  display: block;
}
.form-wrap form .filewrap>input, .form-wrap .filewrap>input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-wrap form .filewrap span, .form-wrap .filewrap span {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #949393;
  line-height: 28px;
}

.form-wrap form .filewrap .upload-btn img, .form-wrap .filewrap .upload-btn img {
  margin-right: 5px;
}

.form-wrap form .filewrap .upload-btn, .form-wrap .filewrap .upload-btn {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 13px;
  background: #3C3F47;
  /* border: 1px solid #55cbc6; */
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 24px;
  color: #A9AAAD;
}

.form-wrap form .form-group.hash img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.form-wrap form .form-group.hash a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 84px);
  display: block;
}

.form-wrap form .form-group.hash img:hover {
  opacity: 0.8;
}

.form-wrap form .form-group.hash .copy {
  right: 50px;
}

.form-wrap form .form-group.hash .go-arrow {
  right: 20px;
}

.form-wrap form .info-wrap {
  position: absolute;
  left: calc(100% + 10px);
  top: 25px;
  width: 20px;
  height: 20px;
  display: flex;
}

.form-wrap form .info-wrap img {
  cursor: pointer;
}

.name-notice {
  position: absolute;
  left: 0;
}

.form-wrap form .info-wrap:hover .info {
  opacity: 1;
  visibility: visible;
}

.form-wrap form .info-wrap .info {
  position: absolute;
  top: 0;
  right: calc(100% + 2px);
  background: #272A33;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.2s;
  top: 10px;
  /* border: 1px solid #55cbc6; */
  width: 270px;
  z-index: 2;
  text-align: left;
  display: flex;
  align-items: flex-start;
}

.form-wrap form .info-wrap .info img {
  margin-right: 12px;
  width: 20px;
}

.form-wrap form .info-wrap .info p {
  width: calc(100% - 32px);
  word-break: break-word;
}

.form-wrap form button, .form-wrap button,
.stake-unstake-modal .collection-wrap button,
.microeconomy-modal.vesting-modal .vesting-btn {
  background: linear-gradient(108deg, #30bd97 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  padding: 12px 15px;
  transition: all ease-in-out 0.2s;
}

.nft-collection {
  margin-top: 20px;
}

.nft-collection .head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.nft-collection .collection-filters {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 20px;
  margin-bottom: 20px;
}

.nft-collection .collection-filters label {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  display: block;
  margin-bottom: 7px;
}

.nft-collection .collection-filters .sort label,
.nft-collection .collection-filters .search label {
  text-transform: uppercase;
}

.nft-collection .collection-filters input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  visibility: hidden;
  display: none;
}

.nft-collection .collection-filters .check {
  position: relative;
  display: block;
  width: 40px;
  height: 24px;
  background: #33353C;
  border: 0.7px solid rgba(255, 255, 255, 0.6);
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: ease-in 0.5s;
  margin: 0 5px;
}

.dao-card .nft-collection .collection-filters .check {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.dao-card .nft-collection .collection-filters {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dao-card .nft-collection .collection-filters .nft-crypto {
  width: 100%;
}

.dao-card .nft-collection .collection-filters .nft-crypto .crypto {
  display: none;
}

.dao-card .nft-collection .collection-filters .nft-crypto.active .crypto {
  display: block;
}

.dao-card .nft-collection .collection-filters .nft-crypto.active .nft {
  display: none;
}

.nft-collection .collection-filters input:checked[type="checkbox"]~.check {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.nft-collection .collection-filters .check:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 4px;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.5s;
}

.nft-collection .collection-filters input:checked[type="checkbox"]~.check:before {
  transform: translateX(15px);
}

.nft-collection .collection-filters .toggle-wrap label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin: 0;
}

.nft-collection .collection-filters input {
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 6.5px 10px;
  color: #fff;
}

.collection-filters span{
  color: #fff;
}

.nft-collection .collection-filters input::placeholder {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.nft-collection .collection-filters .toggle-wrap label {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
}

.nft-collection .collection-filters select,
.microeconomy-head select {
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 6px 20px;
  appearance: none;
  background-image: url("./images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 10px;
}

.nft-collection .collection-filters select option,
.microeconomy-head select option {
  background: #1D2833;
}

.nft-collection .collection-filters .sort {
  min-width: 102px;
}

.nft-collection .collection-filters .category {
  max-width: 78px;
  width: 100%;
}

.nft-collection .collection-filters .category select {
  padding: 6px 10px;
}

.collection-filters button {
  background: rgba(255, 255, 255, 0.05);
  /* border: 1px solid #55cbc6; */
  border-radius: 5px;
  padding: 14px 21px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  line-height: 1;
  margin-left: 12px;
  transition: all ease-in-out 0.2s;
}

.collection-filters button:hover {
  background: rgba(255, 255, 255, 0.08);
}

.collection-filters button img {
  margin-right: 12px;
}

.nft-collection .collection-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
}

.nft-collection .collection-wrap .box {
  width: calc(25% - 24px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 24px;
  margin: 24px 12px;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box{
  width: calc(50% - 24px);
  padding: 0;
  border-radius: 0;
  background: transparent;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .box-inner{
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 24px;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .info-box{
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 24px;
  border: 1px solid #51cec4;
  margin-top: 24px;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .info-box > div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .info-box > div:last-child{
  margin-bottom: 0;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box>a>img,
.nft-collection.circularitydao-stakenft .collection-wrap .box>img,
.nft-collection.circularitydao-stakenft .collection-wrap .box .box-inner>img{
  height: 420px;
  width: 100%;
  object-fit: cover;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .btn-wrap{
  gap: 4%;
  padding: 0;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .btn-wrap button{
  margin: 0 !important;
  width: 48%;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .btn-wrap button.claim{
  background: transparent;
  border: 1px solid #51cec4;
}

.nft-collection.circularitydao-stakenft .collection-wrap .box .btn-wrap button:disabled{
  opacity: 0.2;
  cursor: not-allowed;
}

.light .nft-collection .collection-wrap .box  {
  background: rgba(0, 0, 0, 0.05) !important;
}
.nft-collection .collection-wrap .box .btn-wrap {
  justify-content: space-between;
  display: flex;
  margin-top: 16px;
  /* border: 1px solid #55cbc6; */
  border-radius: 10px;
  padding: 8px;
}

.nft-collection .collection-wrap .box .btn-wrap button {
  border-radius: 100px;
  opacity: 1;
  transition: all ease-in-out 0.2s;
}

.nft-collection .collection-wrap .box .btn-wrap button:hover,
.nft-collection .collection-wrap .box .btn-wrap button.active {
  color: #fff;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  -webkit-text-fill-color: initial;
}

.nft-collection .collection-wrap .box .btn-wrap button:not(:last-child) {
  margin-right: 10px;
  border: 0;
}

.nft-collection .collection-wrap .box>a>img,
.nft-collection .collection-wrap .box>img {
  width: 100%;
    /* border: 1px solid #55cbc6; */

  border-radius: 3px;
  margin-bottom: 19px;
  height: 310px;
  object-fit: cover;
}

.nft-collection .collection-wrap .box .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-collection .collection-wrap .box .profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nft-collection .collection-wrap .box .profile-info img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 7px;
}

.nft-collection .collection-wrap .box .profile-info h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.003em;
  color: #FFFFFF;
}

.nft-collection .collection-wrap .box .profile-info p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.003em;
  color: #A3A3A3;
}

.nft-collection .collection-wrap .box button {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  padding: 4px 20px;
  color: #fff;
}

.nft-collection .collection-wrap .box button.common-btn{
  font-weight: 500;
}

.nft-collection .collection-wrap .box button.common-btn.view-details{
  width: 200px;
  display: block;
  margin: 15px auto 0;
  padding: 10px 20px;
}
.collection-bottom-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-collection .collection-wrap .box p {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.003em;
  color: #FFFFFF;
}

.microeconomy-modal .form-wrap {
  min-height: initial;
  padding: 0;
  display: block;
  max-width: 470px;
  margin: 0 auto;
}

.microeconomy-modal .form-wrap form {
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.microeconomy-modal .form-wrap form button, .microeconomy-modal .form-wrap button {
  display: block;
}

.microeconomy-modal .form-head,
.vesting-schedule .form-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vesting-schedule .form-head h4{
  margin: 0;
  color: #fff;
}

.vesting-schedule .form-head span{
  color: #A9AAAD;
}

.add-more{
  font-size: 16px;
  font-weight: 400;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  cursor: pointer;
}

.vesting-details .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  margin-bottom: 22px;
}

.vesting-details .info span{
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  text-align: left;
}

.vesting-details .info span img{
  margin: 0 8px 0 0 !important;

}
.vesting-details .info p{
  margin-bottom: 8px !important;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body .form-head p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}


.total-number {
  background: #3C3F47;
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 15px 35px;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-number input {
  border: 0 !important;
  background: transparent;
  box-shadow: none !important;
  outline: 0 !important;
  padding: 0 !important;
}

.total-number .number {
  font-weight: 400;
  font-size: 16px;
  color: #A9AAAD;
  line-height: 70px;
  width: calc(100% - 120px);
  text-align: left;
}

.total-number .count-wrap {
    /* border: 1px solid #55cbc6; */

  border-radius: 100px;
  padding: 7px 12px;
}

.total-number .count-wrap span {
  font-weight: 700;
  font-size: 24px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  padding: 3px;
  margin: 0 10px;
}

.total-number .count-wrap span:last-child {
  letter-spacing: -4px;
}

.success-modal .form-wrap span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  margin-bottom: 24px;
  display: block;
}

.stake-unstake-modal .nft-collection .collection-wrap {
  max-width: 360px;
  margin: 0 auto;
  display: block;
}

.stake-unstake-modal .nft-collection .collection-wrap .box {
  margin: 0;
    /* border: 1px solid #55cbc6; */

}

.stake-unstake-modal .nft-collection .collection-wrap button {
  display: block;
  margin-top: 16px;
}

.stake-unstake-modal .nft-collection .collection-wrap .box {
  width: 100%;
}

/* .react-responsive-modal-modal.microeconomy-modal.stake-unstake-modal .modal-body h3 {
  text-align: left;
} */

.react-responsive-modal-modal.microeconomy-modal.create-proposal-modal .head {
  margin-bottom: 20px;
}

.stake-unstake-modal .nft-collection .collection-wrap button {
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  font-weight: 500;
}

.stake-unstake-modal .nft-collection .collection-wrap select {
  text-align: center;
  padding: 13px 30px;
  color: #272A33;
}

.react-responsive-modal-modal.microeconomy-modal.create-proposal-modal h3 {
  display: flex;
  align-items: center;
  text-align: left;
}

.react-responsive-modal-modal.microeconomy-modal.create-proposal-modal h3 img {
  display: inline-block;
  margin: 0;
  margin-left: 20px;
}

.chart-wrapper {
  padding: 15px 0 30px;
  display: flex;
}

.chart-wrapper .bar-chart-wrapper .apexcharts-toolbar {
  display: none;
}

.chart-wrapper .bar-chart-wrapper .apexcharts-inner .apexcharts-xaxis-tick {
  display: none;
}

.chart-wrapper .radial-chart-wrapper .inner,
.chart-wrapper .bar-chart-wrapper .inner {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 10px 15px 0;

}

.chart-wrapper .bar-chart-wrapper {
  width: 100%;
  max-width: 622px;
}

.chart-wrapper .bar-chart-wrapper .inner {
  width: 622px;
  max-width: 100%;
}

.chart-wrapper .radial-chart-wrapper .inner {
  height: calc(100% - 57px);
  display: flex;
  align-items: center;
}

.chart-wrapper .radial-chart-wrapper .inner>div {
  width: 100%;
}

.chart-wrapper .radial-chart-wrapper {
  margin-left: 21px;
  width: 416px;
  max-width: 100%;
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend-text {
  font-size: 14px !important;
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend-text span {
  opacity: 0.4;
  margin-left: 3px;
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend-series {
  margin-bottom: 10px !important;
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend-marker {
  border-radius: 2px !important;
  margin-right: 7px !important;
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend {
  justify-content: center !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.chart-wrapper .radial-chart-wrapper .apexcharts-legend {
  min-height: 170px;
}

.chart-wrapper .bar-chart-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 57px;
}

.chart-wrapper .bar-chart-head .time-duration {
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 20px;
}

.chart-wrapper .bar-chart-head .time-duration span {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.4;
  transition: all ease-in-out 0.2s;
  padding: 3px 9px;
}

.chart-wrapper .bar-chart-head .time-duration span:hover,
.chart-wrapper .bar-chart-head .time-duration span.active {
  background: rgba(255, 255, 255, 0.19);
  border-radius: 60px;
}



.radio-wrap {
  background: #FFFFFF0D !important;
  border: 0.7px solid #ffffff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  padding: 5px 6px;
  justify-content: flex-start;
}

.radio-wrap-mini {
  display: flex !important;
  flex-direction: column !important;
  max-width: 3em !important;
  margin-left: -5px;
}

.radio-wrap p {
  margin: 0 !important;
  opacity: 1 !important;
}

.radio-wrap label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px 12px;
  border-radius: 100px;
}

.radio-wrap-mini label{
  height: 3em;
  padding: 0px;
  padding-left: 0.3em !important;
  min-width: 22px;
}

.radio-wrap input[type="radio"]+label img {
  margin-right: 8px;
}

.radio-wrap-mini input[type="radio"]+label img {
  margin-right: 0px !important;
}

.radio-wrap input[type="radio"] {
  position: absolute;
  left: -9999px;
}

/* .radio-wrap input[type="radio"]+label img.white {
  display: none;
} */

.dark-name {

  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  background-clip: text !important;
}

.dark .radio-wrap input[type="radio"]#dark+label {
  position: relative;
  color: #4fa1b6;
  background: #1D2833 !important;
  -webkit-text-fill-color: inherit;
  padding-left: 8px;
  min-width: 20px;
}

.radio-wrap input[type="radio"]:checked+label {
  position: relative;
  color: #ffffff;
  border-radius: 100px;
  background: #1D2833;
    -webkit-text-fill-color: initial;
    padding-left: 4px;
    min-width: 22px;
}

.dark .radio-wrap input[type="radio"]#dark+label img {
  display: none;
}

.dark .radio-wrap input[type="radio"]#dark+label img.white {
  display: inline-block;
}

/* ILO */
.react-responsive-modal-modal.microeconomy-modal h4 {
  text-align: left;
  color: #fff;
}

.react-responsive-modal-modal.microeconomy-modal.ilo-modal {
  width: 832px !important;
}

.react-responsive-modal-modal.microeconomy-modal.ilo-modal .modal-body {
  max-width: 752px;
}


.initial-offer-wrap .form-wrap .inner {
  width: 100%;
  max-width: 1100px;
}

.initial-offer-wrap .form-wrap .inner form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.initial-offer-wrap .form-wrap .inner form .form-inner {
  width: 46%;
}

.initial-offer-wrap .form-wrap form button {
  max-width: 340px;
  margin: 30px auto 0;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url(images/calendar.png) no-repeat;
  width: 18px;
  height: 20px;
  cursor: pointer;
}

.initial-offer-wrap input[type="date"]:before {
  color: #A9AAAD;
  content: attr(placeholder) !important;
}

.initial-offer-wrap input[type=date]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  color: transparent;
}

.initial-offer-wrap input[type=date]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  color: transparent;
}

.initial-offer-wrap input[type=date]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  color: transparent;
}

.initial-offer-wrap input[type=date]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  color: transparent;
}

.initial-offer-wrap input[type=date]:focus::-webkit-datetime-edit-text {
  color: #A9AAAD;
}

.initial-offer-wrap input[type=date]:focus::-webkit-datetime-edit-month-field {
  color: #A9AAAD;
}

.initial-offer-wrap input[type=date]:focus::-webkit-datetime-edit-day-field {
  color: #A9AAAD;
}

.initial-offer-wrap input[type=date]:focus::-webkit-datetime-edit-year-field {
  color: #A9AAAD;
}

.initial-offer-wrap input[type="date"]:focus:before {
  content: '' !important;
  /* THIS IS THE PROBLEM */
  /* This is ok */
}



/* Borrow */

.react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal {
  width: 1100px !important;
  padding: 15px 40px 40px !important;
}

.react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal .modal-body {
  text-align: left;
  max-width: 100%;
}

.react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal .modal-body h3 {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal .modal-body h3 button {
  display: inline-block;
  margin-left: 20px;
}

.react-responsive-modal-modal.microeconomy-modal button {
  outline: 0;
}

.react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal .modal-body h3 button img {
  display: inline-block;
}

.borrow-manage-wrap {
  display: flex;
  justify-content: space-between;
}

.borrow-manage-wrap .left {
  width: 398px;
}

.borrow-manage-wrap .right {
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.borrow-manage-wrap .info-head {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.borrow-manage-wrap .info-head h4 {
  font-weight: 300;
}

.borrow-manage-wrap .info-box {
  background: rgba(255, 255, 255, 0.05);
    /* border: 1px solid #55cbc6; */

  border-radius: 3px;
  margin-bottom: 60px;
  padding: 16px 14px;
}

.borrow-manage-wrap .info-box .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.borrow-manage-wrap .info-box .info:last-child,
.borrow-manage-wrap .info-box:last-child {
  margin-bottom: 0;
}

.borrow-manage-wrap .info-box .info label {
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
}

.borrow-manage-wrap .left span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #FFFFFF;
}

.borrow-manage-wrap .chart-wrapper .radial-chart-wrapper {
  width: 100%;
  margin: 0;
}

.borrow-manage-wrap .chart-wrapper {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 24px;
}

.borrow-manage-wrap .chart-wrapper h4 {
  min-height: 24px;
}

.borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner {
  height: auto;
  padding: 15px;
    /* border: 1px solid #55cbc6; */

  padding-left: 0;
}

.microeconomy-modal .borrow-manage-form .form-wrap {
  max-width: 100%;
}

.microeconomy-modal .borrow-manage-form .form-wrap form {
  background: #1D2833;
  border-radius: 5px;
  padding: 30px 40px;
}

.borrow-manage-form .form-wrap form input {
  padding-right: 140px;
}

.microeconomy-modal .borrow-manage-form .form-wrap form button {
  margin-top: 35px;
}

.microeconomy-modal .form-wrap form label, .microeconomy-modal .form-wrap label {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 9px;
  display: block;
}

.react-responsive-modal-modal.microeconomy-modal .modal-body .input-info img {
  margin: 0;
}

.input-info {
  display: flex;
  align-items: center;
  width: 130px;
  right: 0;
  position: absolute;
  height: 50px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 2;
  bottom: 10px;
  padding: 10px;
}

.input-info span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding-left: 12px;
}

.microeconomy-modal .form-wrap h4 {
  margin-bottom: 9px;
}


/* Staking */

.react-responsive-modal-modal.microeconomy-modal.eth-stake-unstake-modal {
  width: 540px !important;
}

.microeconomy-modal.eth-stake-unstake-modal .form-wrap {
  max-width: 400px;
}

.react-responsive-modal-modal.microeconomy-modal.eth-stake-unstake-modal .modal-body h3 {
  font-size: 20px;
  text-align: left;
}

.form-wrap form .eth-label {
  position: absolute;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111;
  right: 20px;
}

.eth-stake-unstake-modal .form-wrap form input {
  padding-right: 70px;
}


/* Trading */
.trading-main-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -51px;
  border-left: 0;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-top: 1px solid #55cbc6;
  border-left: 1px solid #55cbc6;
}

.trading-main-wrapper .tabs-wrapper {
  width: calc(100% - 432px);
  border-bottom: 1px solid #55cbc6;
  border-right: 1px solid #55cbc6;
}

.trading-main-wrapper .tabs-wrapper:nth-child(even) {
  width: 432px;
}

.tabs-wrapper ul {
  display: flex;
  border-bottom: 1px solid #55cbc6;
}

.tabs-wrapper ul li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 16px;
  text-align: center;
  border-right: 1px solid #55cbc6;
  background: rgba(255, 255, 255, 0.05);
  display: block;
  transition: all ease-in-out 0.15s;
}

.tabs-wrapper ul li a img {
  margin-left: 10px;
  display: inline-block;
}

/* .tabs-wrapper ul li a img.white {
  display: none;
} */

.tabs-wrapper ul li:last-child a {
  margin-right: -1px;
}

.book-trades ul li {
  width: 50%;
}

.table-info ul li {
  width: 20%;
}

.select-market ul li {
  width: 33.333%;
}

.price-depth ul li {
  min-width: 142px;
}

.tabs-wrapper .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.tabs-wrapper .tab-content>.tab-pane {
  display: none;
}

.tabs-wrapper .tab-content>.active {
  display: block;
}

.tabs-wrapper .fade.show {
  opacity: 1;
}

.tabs-wrapper .nav-tabs .nav-item.show .nav-link,
.tabs-wrapper .nav-tabs .nav-item .nav-link:hover,
.tabs-wrapper .nav-tabs .nav-link.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.tabs-wrapper .nav-tabs .nav-item.show .nav-link img,
.tabs-wrapper .nav-tabs .nav-item .nav-link:hover img,
.tabs-wrapper .nav-tabs .nav-link.active img {
  display: none;
}

.tabs-wrapper .nav-tabs .nav-item.show .nav-link img.white,
.tabs-wrapper .nav-tabs .nav-item .nav-link:hover img.white,
.tabs-wrapper .nav-tabs .nav-link.active img.white {
  display: inline-block;
}

.dashboard .book-trades table {
  width: 100%;
}

.dashboard .book-trades table tr {
  position: relative;
}

.dashboard .book-trades table tr th,
.dashboard .book-trades table tr td {
  text-align: center;
}

.dashboard .book-trades table tr th {
  font-size: 15px;
  color: #FFFFFF;
  padding: 3px 15px;
}

.dashboard .book-trades table tr td {
  font-weight: 500;
  font-size: 12px;
  color: #A9AAAD;
  padding: 0px 15px;
  line-height: 1.3;
}

.dashboard .book-trades table tr:before {
  position: absolute;
  content: '';
  width: 4px;
  height: 7px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dashboard .book-trades table tr.green:before {
  background: #22C55E;
}

.dashboard .book-trades table tr.red:before {
  background: #E14C43;
}

.dashboard .book-trades table thead tr:before {
  background: transparent !important;
}


.dashboard .book-trades table {
  width: 100%;
}

.dashboard .book-trades table {
  width: 100%;
}

.dashboard .book-trades table {
  width: 100%;
}

.book-trades .table-wrap {
  /* max-height: 360px;
  overflow: auto; */
}

.table-info table {
  width: 100%;
}

.dashboard .table-info tr th:first-child,
.dashboard .table-info tr td:first-child {
  width: 200px;
  text-align: left;
}

.dashboard .table-info tr td:first-child img {
  width: 29px;
  display: inline-block;
  margin-right: 6px;
}

.dashboard .table-info tr th,
.dashboard .table-info tr td {
  text-align: center;
  padding: 8px 24px;
  letter-spacing: 0.5px;
}

.dashboard .table-info tr th {
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
}

.dashboard .table-info tr td {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}

.select-market .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #55cbc6;
  padding: 5px 15px;
  min-height: 57px;
  background: rgba(255, 255, 255, 0.05);
}

.select-market .head>div {
  display: flex;
  align-items: center;
}

.select-market .head>div img {
  width: 20px;
  margin-right: 8px;
}

.select-market .head>div span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.select-market .head select {
  text-align: right;
  width: 200px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  padding-right: 18px;
  background-color: transparent;
  border: 0 !important;
  background-image: url("./images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px;
  width: auto;
  outline: 0 !important;
  box-shadow: none !important;
}

.select-market .head select option {
  color: #272A33;
}

.select-market .form-wrap {
  padding: 14px;
}

.select-market .form-wrap form {
  width: 100%;
  padding: 0;
}

.select-market form label {
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 5px;
  display: block;
}

.select-market .form-wrap form input {
  padding: 15px;
  height: 56px;
  padding-right: 80px;
}

.select-market .form-group .currency-label {
  position: absolute;
  right: 15px;
  z-index: 1;
  top: 16px;
}

.select-market .form-group .currency-label span,
.select-market .form-group .currency-label select {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.select-market .form-group .currency-label select {}

.select-market .form-group .currency-label select {
  text-align: right;
  padding: 0;
  padding-right: 18px;
  background-color: transparent;
  border: 0 !important;
  background-image: url("./images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px;
  width: auto;
  outline: 0 !important;
  box-shadow: none !important;
}

.select-market .form-group .currency-label option {
  color: #272A33;
  text-align: left;
}

.select-market .btn-wrap {
  display: flex;
  justify-content: space-between;
}

.select-market form .btn-wrap button {
  width: 49%;
  background: rgba(255, 255, 255, 0.05);
    /* border: 1px solid #55cbc6; */

}

.select-market form .btn-wrap button:hover {
  opacity: 1;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.select-market .rangeslider-wrap {
  margin: 25px 10px 45px;
}

.select-market .fee-info {
  margin-bottom: 20px;
}

.select-market .fee-info .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 5px;
}

.select-market .fee-info .info:last-child {
  margin-bottom: 0;
}

.select-market .fee-info .info p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
}

.select-market .limit-wrap {
  margin-bottom: 10px;
}

.select-market .radio-wrapper input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.select-market .radio-wrapper label {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;
}

.select-market .radio-wrapper input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.select-market .radio-wrapper label img {
  display: inline-block;
  margin-right: 5px;
}

.select-market .radio-wrapper label img.fill {
  display: none;
}

.select-market .radio-wrapper input[type="radio"]:checked+label img {
  display: none;
}

.select-market .radio-wrapper input[type="radio"]:checked+label img.fill {
  display: inline-block;
}

.select-market .radio-wrapper p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.select-market .radio-wrapper p .form-group {
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  margin: 0;
}

.select-market .radio-wrapper p .form-group {
  width: 100%;
}

.select-market .radio-wrapper input[type="radio"]:checked+label~.form-group {
  height: auto;
  overflow: visible;
  margin-bottom: 15px;
}

.select-market .select-wrap {
  display: flex;
  align-items: center;
}

.select-market .select-wrap>span {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: #A9AAAD;
  margin-right: 5px;
  letter-spacing: 0.5px;
}

.select-market .select-wrap select {
  text-align: right;
  padding: 5px;
  padding-right: 12px;
  background-color: transparent;
  border: 0 !important;
  background-image: url("./images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px;
  width: auto;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #FFFFFF;
}

.select-market .select-wrap option {
  color: #272A33;
  text-align: left;
}

#chart-candlestick {
  background: #000;
}

.price-depth .tab-content {
  height: calc(100% - 56px);
}

.price-depth .tab-content .tab-pane,
#chart-candlestick {
  height: 100%;
}

.price-depth .tab-content {
  height: calc(100% - 57px);
}

#chart-candlestick .apexcharts-xaxis-label,
#chart-candlestick .apexcharts-yaxis-label {
  fill: #585859;
  font-weight: 700 !important;
  font-size: 13px;
}

#depth-chart {
  background: #000;
}

#depth-chart,
#zc {
  height: 100%;
}

.right-main-content {
  width: calc(100% - 290px);
  margin-left: auto;
}

.dashboard-pool-table th:first-child {
  width: 220px;
}

.dashboard-pool-table td img {
  display: inline-block;
}

.dashboard-pool-table td.red p,
.dashboard-pool-table td.green p {
  display: inline-block;
  padding-left: 12px;
}

/* Only Light Theme Work Below */

.light .microeconomy-head .logo-wrap img {
  filter: brightness(0);
}

.light .radio-wrap input[type="radio"]#light+label {
  position: relative;
  color: #fff;
  background: #1D2833;
  -webkit-text-fill-color: inherit;
}

.light .customScroll ::-webkit-scrollbar-track {
  background: rgba(29, 40, 51, 0.10);
}

.light .radio-wrap input[type="radio"]#light+label img {
  position: relative;
  color: #fff;
  /* background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%); */
}

.light .radio-wrap input[type="radio"]#light+label img {
  display: none;
}

.light .radio-wrap {
  background: transparent;
}

.light .radio-wrap input[type="radio"]#light+label img.white {
  display: inline-block;
}

.light.main-body {
  background: radial-gradient(93.99% 67.79% at 33.98% 32.22%, rgba(110, 180, 215, 0.26) 0%, rgba(59, 228, 183, 0.103) 100%);
}

.light .microeconomy-head .logo-wrap span,
.light .microeconomy-head .btn-wrap select,
.light .microeconomy-head .btn-wrap button,
.light .microeconomy-wrapper h2,
.light .microeconomy-tool .inner .box h3,
.light .chart-wrapper .bar-chart-head .time-duration span,
.light .nft-collection .collection-filters label,
.light .nft-collection .collection-filters select,
.light .nft-collection .collection-wrap .box .profile-info h4,
.light .microeconomy-wrapper table tr td span,
.light .microeconomy-wrapper table tr td,
.light .microeconomy-wrapper table tr td p,
.light .pagination ul li,
.light .detail-row form input,
.light .profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address span,
.light .profile-wrapper .inner .inner-wrap .right .content p,
.light .profile-wrapper .nav-tabs li a,
.light .form-wrap form .info-wrap .info p,
.light.stake-unstake-modal .nft-collection .collection-wrap select,
.light .borrow-manage-wrap .left span,
.light.microeconomy-modal .form-wrap form label,
.light .input-info span,
.light .sell-wrap .content span,
.light .sell-wrap .content p,
.light .marketplace-collection-wrap .inner p,
.light .marketplace-collection-wrap .minting-now p,
.light .marketplace-collection-wrap .collection-info-list span,
.light .multiple-collections .collection-box .collection-info h6,
.light .vesting-schedule .form-head h4,
.light .vesting-details .info span,
.light.microeconomy-modal .form-wrap label {
  color: #272A33;
}

.light .vesting-schedule .form-head h4 {
  font-weight: 500;
}

.light .sell-wrap .item-interaction .item-views span,
.light .microeconomy-head .btn-wrap .select-wrap .options .option:hover {
  color: #fff;
}

.light .nft-collection .collection-wrap .box .profile-info p {
  color: #3C3F47;
}

.light .microeconomy-head .btn-wrap button span {
  border-left: 2px solid #272A33;
}

.light .microeconomy-head .btn-wrap button {
  border: 1px solid rgba(29, 40, 51, 0.50);
}

.light .microeconomy-head select option,
.light .form-wrap form,
.light .form-wrap form input,
.light .form-wrap form textarea,
.light .form-wrap form .filewrap .upload-btn,
.light .form-wrap form .filewrap,
.light .microeconomy-head select option,
.light .microeconomy-wrapper table tr {
  background: transparent;
}

.light .microeconomy-tool .inner .box {
  background: #fff;
  border: 0;
}

.light .microeconomy-tool .inner .box img {
  filter: brightness(0);
}

.light .form-wrap form,
.light .chart-wrapper .radial-chart-wrapper .inner,
.light .chart-wrapper .bar-chart-wrapper .inner,
.light .nft-collection .collection-wrap .box,
.light .microeconomy-wrapper table tr {
    /* border: 1px solid #55cbc6; */

}

.light .form-wrap form {
  background: #fff;
  border: 0;
}

.light .form-wrap form input,
.light .form-wrap form textarea,
.light .form-wrap form .filewrap,
.light .total-number,
.light .form-wrap input,
.light .form-wrap textarea,
.light .form-wrap .filewrap,
.light .total-number {
  background: rgba(29, 40, 51, 0.10);
}

.light .form-wrap form .checkbox-wrapper input{
  background: rgba(29, 40, 51, 0.10) !important;
}

.light .form-wrap .inner>p {
  color: #1D2833;
}

.light .chart-wrapper .bar-chart-head .time-duration span:hover,
.light .chart-wrapper .bar-chart-head .time-duration span.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  opacity: 1;
  color: #fff;
}

.light .apexcharts-text tspan {
  fill: #272A33;
}

.light .chart-wrapper .radial-chart-wrapper .apexcharts-legend-text {
  color: #272A33 !important;
}

.light .microeconomy-wrapper table thead tr {
  border: 0
}

.light .nft-collection .collection-wrap .box p,
.light .tabs-wrapper-ilo .nav-tabs .nav-item .nav-link.active,
.light .marketplace-collection-wrap .inner h2,
.light .marketplace-collection-wrap .collection-info-list p,
.light .multiple-collections .collection-box .collection-info h3,
.light .multiple-collections .collection-box .collection-info .head span,
.light .view-all .table-wrapper table tbody td.vote-count p {
  color: #000;
}

.light .profile-wrapper .inner,
.light .profile-wrapper .inner .profile-bottom,
.light .form-wrap form .info-wrap .info,
.light .tabs-wrapper-ilo,
.light .marketplace-collection-wrap .collection-info-list,
.light .microeconomy-head .btn-wrap .select-wrap .options {
  background: #fff
}

.light.microeconomy-modal .borrow-manage-form .form-wrap form,
.light .marketplace-collection-wrap .minting-now,
.light .multiple-collections .collection-box {
  background: #fff;
    /* border: 1px solid #55cbc6; */

}

.light .multiple-collections .collection-box button {
  background: #fff !important;
  border-top: 1px solid #55cbc6 !important;
  color: #000 !important;
  border-radius: 0 0 10px 10px !important;
}

.light .multiple-collections .collection-box button:hover {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  color: #fff !important;
}

.light .sell-wrap .item-interaction .item-views span {
  background: var(--botn, linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%)) !important;
}

.light .vesting-details .info{
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
/* Light theme end */
.borrow-table {
  width: 100%;
}
.copy-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content:center;
}

.copy-wrap p{
  color: #A9AAAD;
  text-align: center;
}

.copy-wrap a {
  margin-top: 5px;
  margin-right: 8px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}

.copy-wrap a {
  margin-top: 5px;
  margin-right: 8px;
  word-break: break-all;
  display: flex;
  flex-direction: column;
}

.copy-wrap span {
  display: inline;
}

.copy-wrap span img{
  display: inline;
  padding-left: 8px;
}
.borrow-table td {
  padding: 0 10px;
}

.borrow-table .borrow-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid #474747;
  border-radius: 10px;
  padding: 8px 20px 8px 8px;
  width: 320px;
}

.borrow-btns button {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
}

.borrow-btns .manage-position {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.stability-btn button {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #0A0A0A;
  padding: 0;
  gap: 10px;
}

.staking .logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

}

.staking .logo-wrap p {
  margin: 0;
}

.claim-btn button {
  border: 1px solid #474747;
  border-radius: 100px;
  background: transparent;
  color: #474747;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
}

.claim-btn button.active {
    /* border: 1px solid #55cbc6; */

  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard .bar-chart-head span {
  font-weight: 600;
  font-size: 15px;
  line-height: 36px;
  color: #7E7F84;
}

.dashboard .bar-chart-wrapper .bar-chart-head h2 {
  margin: 0;
  font-weight: 500;
  font-size: 30px;
  line-height: 24px;
}

.dashboard .bar-chart-head p {
  color: #29B32F;
  font-weight: 600;
  font-size: 10px;
  line-height: 36px;
}

.dashboard .bar-chart-head p span {
  font-weight: 600;
  font-size: 10px;
  line-height: 36px;
}

.dashboard table {
  padding-bottom: 20px;
}

.dashboard table thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #B0B7C3;
  padding: 0 18px 29px;
}

.dashboard table .star {
  width: 11.67px;
}

.microeconomy-wrapper table tr td.red p {
  color: #F15B5B;
}

.microeconomy-wrapper table tr td.green p {
  color: #22C55E;
}

.dashboard table tbody td {
  padding: 12px 18px;
}

.dashboard table tr td span span {
  color: #B0B7C3;
}

.dashboard .chart-wrapper .bar-chart-head {
  align-items: flex-end;
  min-height: 96px;
}

.dashboard .chart-wrapper .radial-chart-wrapper .inner {
  height: calc(100% - 96px);
}

.borrow .chart-wrapper .radial-chart-wrapper {
  margin-left: 0;
}

.wallet-extension .wallet-extension-wrap {
  text-align: center;
  max-width: 569px;
  padding: 44px 0 58px;
  width: 100%;
  margin: 0 auto;
}

.swap .swap-settings-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.swap .swap-settings-dropdown .dropdown-header {
  padding: 0;
  position: absolute;
  top: 8px;
  right: 9px;
}

.swap .swap-settings-dropdown .dropdown-header h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.swap .swap-settings-dropdown .dropdown-header button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.swap .swap-settings-dropdown .dropdown-wrapper .form-group label {
  font-weight: 500;
  font-size: 15px;
  line-height: 36px;
  color: #FFFFFF;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.swap .swap-settings-dropdown .dropdown-wrapper .form-group>div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.swap .swap-settings-dropdown .dropdown-wrapper .form-group>div button {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}

.swap .swap-settings-dropdown .dropdown-wrapper .form-group:first-child {
  margin-bottom: 4px;
}

.wallet-extension .wallet-extension-wrap input,
.wallet-extension .wallet-extension-wrap select {
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 42px;
  width: 100%;
  padding: 0 24px;
  outline: none;
}



.swap .swap-settings-dropdown .settings-dropdown {
  cursor: pointer;
}


.wallet-extension .wallet-extension-wrap .form-wrapper .form-group {
  margin-bottom: 38px;
}

.swap .form-wrapper {
  padding: 48px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.swap .form-wrapper .form-head{
  display: flex;
  justify-content: space-between;
}

.swap .form-group .input-group {
  display: flex;
  grid-template-columns: 1.5fr 1fr;
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  height: 83px;
  width: 100%;
  padding: 8px 20px 8px 24px;
  outline: none;
  align-items: center;
}

.swap .form-group .input-group input {
  background: transparent;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  height: 100%;
  width: calc(100% - 130px);
}

.swap .network-dropdown {
  padding-left: 12px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 120px;
}

.swap .swap-settings-dropdown .dropdown-wrapper {
  position: absolute;
  right: 0;
  width: 340px;
  background: #272A33;
  border: 0.7px solid #55cbc6;
  padding: 26px 51px 31px 51px;
  top: 55px;
  z-index: 1;
}

.swap .network-dropdown .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.swap .network-dropdown .header p {
  font-size: 15px;
}

.swap .network-dropdown .header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.swap .form-group button {
  background: transparent;
  border: none;
  margin: 8px 0;
}

.swap .btn {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  width: 100%;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #FFFFFF;
  border: none;
}

/* Range Slider */
.rangeslider-wrap {
  margin: 30px 5px 60px;
}

.rc-slider-rail {
  height: 3px !important;
  background-color: #474747 !important;
}

.rc-slider-handle {
  width: 17px !important;
  height: 15px !important;
  background-color: transparent !important;
  border: 0 !important;
  opacity: 1 !important;
  top: 0;
  margin: 0 !important;
  box-shadow: none !important;
  background-image: url(images/subtab-fill-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}

.rc-slider-dot {
  width: 9px !important;
  height: 9px !important;
  background-color: transparent !important;
  border: 0 !important;
  opacity: 1 !important;
  box-shadow: none !important;
  background-image: url(images/slider-dot.png);
  background-repeat: no-repeat;
  background-size: 7px;
  z-index: 2;
  bottom: -2px !important;
  background-color: #1D2833 !important;
  background-position: center;
}

.rc-slider-track {
  height: 3px !important;
  background-color: transparent !important;
}

.rc-slider-mark-text {
  font-weight: 300 !important;
  font-size: 12px !important;
  color: #A9AAAD !important;
}

.swap .network-dropdown .dropdown-wrapper {
  background: #272A33;
  border: 0.7px solid #55cbc6;
  min-width: 200px;
  position: absolute;
  top: 60px;
  z-index: 1;
}

.swap .network-dropdown .dropdown-wrapper li {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.microeconomy-wrapper table tr.no-border {
  height: 10px;
  border: 0 !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
}

.pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.pagination ul li {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}

.transaction-amount {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.transaction-amount>img {
  margin: 0 !important;
}

.transaction-amount .amount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.transaction-amount .amount span {
  margin: 0;
}

.transaction-amount .amount img {
  margin: 0 !important;
}


/* Add Liquidity Modal */

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .head h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .head span {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity {
  text-align: left;
}

.microeconomy-modal.add-liquidity .form-wrap {
  max-width: 100%;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body {
  text-align: left;
  max-width: 530px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper p {
  margin-top: 3px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper label {
  width: 100%;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper input {
  padding-right: 130px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .input-info {
  bottom: auto;
  top: 10px;
  width: 120px;
  cursor: pointer;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .input-info span {
  padding-left: 0;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .input-info img {
  margin-right: 10px;
}


.react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper .form-group {
  width: 49%;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .form-wrap label {
  font-size: 18px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .form-wrap input {
  padding-left: 20px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .total-number {
  padding-left: 20px;
}

.react-responsive-modal-modal.microeconomy-modal.add-liquidity .total-number input {
  background: transparent;
}

.form-wrap .label {
  position: absolute;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111;
  right: 20px;
  cursor: pointer;
}


.form-wrap form input .label-handle input {
  padding-right: 70px;
}

.min-prices-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.min-prices-wrapper .form-group-wrapper {
  width: 49%;
}

.react-responsive-modal-modal.microeconomy-modal.select-token .modal-body {
  max-width: 100%;
  text-align: left;
}

.select-token.microeconomy-modal .form-wrap {
  max-width: 530px;
}

.select-token .btn-wrap {
  display: flex;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.select-token .form-wrap form .btn-wrap button, .select-token .form-wrap .btn-wrap button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-right: 10px;
}

.select-token .form-wrap form .btn-wrap button img, .select-token .form-wrap .btn-wrap button img  {
  margin: 0;
  margin-right: 10px;
}

.list-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 112px;
  overflow: auto;
}
.list-wrapper .list-item{
  list-style: none;
  margin-bottom: 8px;
}
.list-wrapper .list-item .token-detail{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 7px 13px 4px;
  background: transparent;
  border: 0.7px solid #55cbc6;
  min-width: 150px;
  }

  .list-wrapper .list-item .token-detail img{
    width: 20px;
    margin: 0;
    margin-right: 8px;
  }
  .list-wrapper .list-item .token-detail h4{
    margin: 0;
    line-height: 1;
    font-size: 16px;
    }

  .list-wrapper .list-item .token-detail span{
    color: rgba(255,255,255,0.7);
    font-weight: normal;
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    display: block;
    letter-spacing: 0.4px;
    }

    .light .list-wrapper{
      border-top: 1px solid rgba(0,0,0,0.1);
    }

    
.form-wrap form .form-group.search input, .form-wrap .form-group.search input {
  background-image: url("./images/search.png");
  background-position: 10px 52%;
  background-repeat: no-repeat;
}

button.add-liquidity {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  border: none;
  padding: 5px 10px;
}

.liquidity-percentage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.liquidity-percentage button{
  width: 23%;
}
/* ILO Tabs */
.tabs-wrapper-ilo {
  background: #1D2833;
  border-radius: 10px;
  overflow: hidden;
}

.tabs-wrapper-ilo .nav-tabs {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  display: flex;
  padding: 10px 15px;

}

.tabs-wrapper-ilo .nav-tabs li {
  margin-right: 40px;
}

.tabs-wrapper-ilo .nav-tabs .nav-item .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #525252;
}

.tabs-wrapper-ilo .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.tabs-wrapper-ilo .tab-content>.tab-pane {
  display: none;
}

.tabs-wrapper-ilo .tab-content>.active {
  display: block;
}

.tabs-wrapper-ilo .fade.show {
  opacity: 1;
}

.tabs-wrapper-ilo .nav-tabs .nav-item.show .nav-link,
.tabs-wrapper-ilo .nav-tabs .nav-item .nav-link:hover,
.tabs-wrapper-ilo .nav-tabs .nav-link.active {
  color: #000;
}

.ilo-invest {
  margin-top: 50px;
}

.ilo-invest .token-list ul {
  height: 272px;
  overflow: auto;
}

.ilo-invest .token-list ul li {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  background: #3C3F47;
  cursor: pointer;
}

.ilo-invest .token-list ul li:last-child {
  border-bottom: 0 !important;
}

.ilo-invest .token-list ul li .left {
  display: flex;
}
.ilo-invest .token-list ul li .right{
  width: 400px;
}

.ilo-invest .token-list ul li .left .info {
  color: #fff;
}

.ilo-invest .token-list ul li .left img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.ilo-invest .token-list ul li h3 {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}

.ilo-invest .token-list ul li span {
  color: #fff;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
}

.ilo-invest .token-list ul li p {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 16px;
}


/* ilo-detail-tabs */
.ilo-detail-tabs-wrapper .nav-tabs {
  display: flex;
  border-bottom: 1px solid #55cbc6;
}

.ilo-detail-tabs-wrapper .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.ilo-detail-tabs-wrapper .tab-content>.tab-pane {
  display: none;
}

.ilo-detail-tabs-wrapper .tab-content>.active {
  display: block;
}

.ilo-detail-tabs-wrapper .fade.show {
  opacity: 1;
}

.ilo-detail-tabs-wrapper .nav-tabs .nav-item {
  display: flex;
}

.ilo-detail-tabs-wrapper .nav-tabs .nav-item .nav-link {
  /* border-radius: 5px 5px 0 0; */
  font-size: 18px;
  color: #fff;
  padding: 5px 15px;
  width: 90px;
  transition: all ease-in-out 0.2s;
}

.ilo-detail-tabs-wrapper .nav-tabs .nav-item.show .nav-link,
.ilo-detail-tabs-wrapper .nav-tabs .nav-item .nav-link:hover,
.ilo-detail-tabs-wrapper .nav-tabs .nav-link.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}

.ilo-detail-tabs-wrapper .tab-content ul li {
  display: flex;
  padding: 10px 0;
  align-items: center;
}

.react-responsive-modal-modal.microeconomy-modal.invest-list-modal {
  width: 750px !important;
}

.react-responsive-modal-modal.microeconomy-modal.invest-list-modal .modal-body {
  max-width: 94%;
}

.ilo-detail-tabs-wrapper .tab-content {
  padding-top: 15px;
}

.ilo-detail-tabs-wrapper .tab-content ul li p {
  padding-left: 15px;
  font-size: 18px !important;
  line-height: 1.2 !important;
}

.ilo-detail-tabs-wrapper .tab-content ul li p.token-address {
  padding: 5px 10px;
  border: 1px solid rgb(75, 75, 75);
  margin-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.ilo-detail-tabs-wrapper .tab-content ul li p.token-address img {
  margin: 0 !important;
  margin-left: 8px !important;
  cursor: pointer;
}

.ilo-detail-tabs-wrapper .tab-content ul li h4 {
  font-size: 18px !important;
  line-height: 1.2 !important;
}

.microeconomy-modal .action-content .form-wrap {
  max-width: 100%;
  text-align: left;
  padding-top: 10px;
}

.action-content h2 {
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
}

.action-content .form-wrap form button {
  width: auto;
  min-width: 185px;
  height: 55px;
}

.action-content .presale-status {
  margin-bottom: 30px;
}

.action-content .form-wrap form input {
  padding: 10px 20px;
  height: 55px;
  margin-right: 10px;
}

.action-content .form-wrap form .form-group {
  display: flex;
}

.action-content .form-wrap form .btn-wrapper button {
  width: 100%;
  margin-top: 10px;
}

.detail-row h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0;
}

.microeconomy-head .btns {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  margin-right: 12px;
}

.microeconomy-head .btns button {
  padding: 12px 24px;
}

.btns button {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 8px;
  padding: 18px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  flex-grow: 1;
}

.dao-genesis .detail-row .detail-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  margin-bottom: 75px;
}

.dao-genesis .detail-row .detail-list li {
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 8px;
  height: 120px;
  padding: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
}

.dao-genesis .detail-row h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0;
}

.dao-genesis .detail-row .donate-token {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  max-width: 640px;
  margin: 0 auto;
  margin-bottom: 48px;
  padding: 48px;
}

.dao-genesis .detail-row .donate-token h3 {
  margin-bottom: 24px;
}

.dao-genesis .detail-row .donate-token p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.dao-genesis .detail-row .donate-token p:last-child {
  margin-bottom: 0;
}

.dao-genesis .detail-row .donate-token p span {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dao-genesis .detail-row .donate-btn {
  text-align: center;
}

.dao-genesis .detail-row .donate-btn button {
  padding: 16px 48px;
}

.modules-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}

.modules-list li {
  background: rgba(255, 255, 255, 0.05);
  border: 0.7px solid #55cbc6;
  border-radius: 8px;
  padding: 28px 24px 40px;
  text-align: center;
}

.dao-genesis .detail-row .modules-list li h3 {
  margin-bottom: 12px;
}

.modules-list li p {
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.4;
}

.detail-row .dao-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
  row-gap: 30px;
  position: relative;
  margin-bottom: 48px;
}

.detail-row .dao-info::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 1px;
  height: 100%;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.detail-row .dao-info h3 {
  text-align: left;
  margin-bottom: 16px;
}

.detail-row .dao-info .nft-crypto-card h3 {
  font-size: 32px;
  line-height: 1.2;
}

.detail-row .dao-info .form-group {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  gap: 16px;
}

.detail-row form input {
  width: 100%;
  display: block;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid #55cbc6 !important;
  border-radius: 8px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  padding: 15px 24px !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.detail-row .dao-info p {
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  opacity: 0.4;
  margin-bottom: 20px;
  text-align: left;
}

.detail-row .dao-info a {
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  opacity: 0.4;
  margin-bottom: 20px;
  text-align: left;
}

.detail-row .dao-info button {
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  margin-top: 8px;
}

.detail-row .dao-btn {
  text-align: center;
}

.detail-row .dao-btn a {
  padding: 16px 75px;
}

.view-all .detail-row .detail-list li h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
  color: #FFFFFF;
  text-align: left
}

.view-all .detail-row .detail-list li {
  height: auto;
  align-items: center;
}

.view-all .detail-row .detail-list {
  margin-bottom: 15px;
}

.view-all .detail-row .detail-list li p {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.view-all .filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 32px;
}

.view-all .table-wrapper {
  overflow: auto;
}

.view-all .table-wrapper.view-pools-table .wallet-address{
  display: flex;
  align-items: center;
}

.view-all .table-wrapper.view-pools-table .wallet-address img{
  margin-left: 5px;
}

.view-all .table-wrapper table {
  min-width: 700px;
}

.submit-proposal.view-all .table-wrapper table {
  min-width: 920px;
}

.submit-proposal.view-all .filters {
  grid-template-columns: 3fr 1fr;
}

.view-all .filters .search-field {
  position: relative;
}

.view-all .filters .search-field input{
  background: #3C3F47;
  border: 0;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A9AAAD;
  padding: 16px 35px;
  width: 100%;
}

.view-all .filters .search-field img {
  position: absolute;
  top: 16px;
  right: 16px;
}

.view-all .filters .btns {
  display: flex;
  align-items: center;
  gap: 15px;
}

.view-all .table-wrapper h2 {
  text-align: left;
}

.view-all .table-wrapper table {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  width: 100%;
}

.view-all .table-wrapper table thead {
  border-color: #ffffff1a;
  text-align: left;
}

.view-all .table-wrapper table thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.view-all .table-wrapper table tbody td .info-edit{
  width: 20px;
  cursor: pointer;
}
.view-all .table-wrapper table tbody td {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  border: none;
  padding: 16px;
}
.proposal-info-modal .modal-body{
  text-align: center;
}
.react-responsive-modal-modal.microeconomy-modal.proposal-info-modal .modal-body p{
  text-align: center;
  margin: 20px auto;
}
.proposal-info-modal .modal-body span{
  color: #fff;
}
.view-all .table-wrapper table tbody td.status {
  color: rgba(255, 255, 255, 0.4);
}

.view-all .table-wrapper table tbody td.status.green {
  color: #05FF00;
}

.view-all .table-wrapper table tbody tr.active td.status {
  color: #05FF00;
}

.view-all .table-wrapper table tbody tr td.vote-reject img {
  width: 20px;
  height: 20px;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject img {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.view-all .table-wrapper table tbody tr td.vote-reject img.expired {
  cursor: not-allowed;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject img.expired {
  display: none;
}

.view-all .table-wrapper table tbody tr:not(.active) td.vote-reject img.expired {
  display: inline-block;
}

.view-all .table-wrapper table tbody tr:not(.active) td.vote-reject img:not(.expired),
.view-all .table-wrapper table tbody tr.active td.vote-reject img.rejected,
.view-all .table-wrapper table tbody tr.active td.vote-reject img.voted {
  display: none;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-vote {
  display: none;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.voted {
  display: inline-block;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.do-reject {
  display: none;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject.vote-done>p.active img.rejected {
  display: inline-block;
}

.view-all .table-wrapper table tbody tr.active td.vote-reject img:hover {
  opacity: 0.8;
}

.view-all .table-wrapper table tbody td.options {
  display: flex;
  align-items: center;
  gap: 35px;
}

.view-all .table-wrapper table tbody td.options a {
  border-radius: 2px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 8px;
}

.view-all .table-wrapper table tbody td.options img {
  cursor: pointer;
}

.view-all .detail-row form input {
  margin: 0;
}

.view-all .table-wrapper table tbody td.vote-count p {
  display: inline-block;
  color: rgba(255, 255, 255, 0.40);
  font-size: 12px;
  margin-right: 20px;
}

.view-all .table-wrapper table tbody td.vote-count p:first-child {
  width: 110px;
}

.view-all .table-wrapper table tbody td.vote-count p:last-child {
  display: inline-block;
  font-size: 12px;
  margin-right: 0;
}

.view-all .table-wrapper table tbody td.vote-count p img {
  display: inline-block;
  margin-right: 8px;
  vertical-align: text-top;
}

.view-all .table-wrapper table tbody td.vote-reject p {
  display: inline-block;
  margin-right: 24px;
  vertical-align: middle;
}

.view-all .table-wrapper table tbody td.vote-reject p:last-child {
  margin-right: 0;
}

.microeconomy-tool.view-pool{
  max-width: 1200px;
}

.view-pools-table table button.add-liquidity{
  font-size: 14px;
  padding: 9px 10px;
  width: 141px;
  margin: 0;
}

.view-pool .table-wrapper.view-pools-table table{
  min-width: 1050px;
}
/* onboard-v2 */
/* .ac-container.svelte-1nua59o{
  display: none;
} */
.light .dao-genesis .detail-row h3,
.light .dao-genesis .detail-row .donate-token p,
.light .modules-list li h3,
.light .modules-list li p,
.light .detail-row .dao-info h3,
.light .detail-row .dao-info p,
.light .view-all .detail-row .detail-list li h2,
.light .view-all .table-wrapper table tbody td,
.light .swap .network-dropdown .header p,
.light .ilo-invest .token-list ul li h3,
.light .ilo-invest .token-list ul li span,
.light .swap .swap-settings-dropdown .dropdown-wrapper .form-group label,
.light .wallet-extension .wallet-extension-wrap input,
.wallet-extension .wallet-extension-wrap select,
.light .tabs-wrapper ul li a,
.light .select-market .head>div span,
.light .select-market form label,
.light .select-market .form-group .currency-label span,
.light .select-market .form-group .currency-label select,
.light .dashboard .table-info tr th,
.light .dashboard .book-trades table tr th,
.light .checkbox-wrap label,
.light .nft-collection .collection-filters input {
  color: #272A33;
  opacity: unset;
}

.light .view-all .table-wrapper table thead th {
  border-bottom: 1px solid #55cbc6;
}

.light .detail-row form input {
  color: #272A33 !important;
}

.select-market .form-wrap form {
  background: transparent !important;
}

.light .swap .form-wrapper {
  background: #fff;
  border: 0;
}

.light .swap .network-dropdown,
.light .input-info {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}

.light .swap .form-group .input-group {
  background: rgba(29, 40, 51, 0.10);
  border: 0;
}

.light .tabs-wrapper-ilo .nav-tabs .nav-item .nav-link {
  color: #fff;
}

.light .dao-genesis .detail-row .donate-token {
    /* border: 1px solid #55cbc6; */

}

.light .ilo-invest .token-list ul li,
.light .swap .swap-settings-dropdown .dropdown-wrapper {
  background: #fff;
  border-bottom: 1px solid #55cbc6;
}

.light .launch-dao .detail-row {
    /* border: 1px solid #55cbc6; */

  padding: 28px;
}

.sample-csv-file {
  display: flex;
  align-items: center;
}

.form-wrap form .choose-file input {
  height: 87px;
}

.sample-csv-file img {
  margin-right: 8px;
}

.staking-table .borrow-btns {
  min-width: 200px;
  display: flex;
}

.staking-table {
  min-width: 950px;
}

.staking-table .borrow-btns button {
  margin: 0 10px;
}

.stability-btn {
  min-width: 210px;
}

.bg-open {
  position: fixed;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  display: none;
}



.sidebar-arrow {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.20);
  display: flex !important;
  position: fixed;
  left: 0;
  top: 10%;
  transform: translateY(-50%);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
  z-index: 11;
  box-shadow: 0px 5px 20px rgba(0.0.0.0.5);
}

.light .sidebar-arrow {
  background: rgba(22, 29, 35, 0.90);
}

.sidebar-arrow img {
  /* transform: rotate(-135deg); */
  transition: all ease-in-out 0.3s;
}

.sidebar-arrow.active {
  left: 290px;
  margin-left: -2px;
}

.sidebar-arrow.active img {
  transform: rotate(180deg);
}

.user-profile-icon {
  width: 44px;
  height: 44px;
  margin-left: auto;
  margin-right: 12px;
}

.user-profile-icon img {
  width: 100%;
}

.profile-wrapper {
  margin-top: 20px;
}

.profile-wrapper .inner {
  background: #1D2833;
  max-width: 1024px;
  border-radius: 8px;
  overflow: hidden;
}

.profile-wrapper .inner .inner-wrap {
  display: flex;
  padding: 32px 32px 24px;
}

.profile-wrapper .inner .inner-wrap .img-wrap, .steps-form-wrapper .step .img-wrap{
  width: 172px;
  height: 172px;
  background: #1a242e;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.steps-form-wrapper .step .img-wrap{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #3c3f47;
}
.steps-form-wrapper .step .upload-img{
  display: flex;
  align-items: center;
}
.steps-form-wrapper .step label{
  display: block;
  margin-right: 20px;
}

.profile-wrapper .inner .inner-wrap .img-wrap > img, .steps-form-wrapper .step  .img-wrap > img {
  width: 100%;
  border-radius: 8px;
  filter: invert();
  width: 50px;
  height: 50px;
  transition: all ease-in-out 0.2s;
}

.steps-form-wrapper .step  .img-wrap > img{
  width: 30px;
  height: 30px;
}
.steps-form-wrapper .step .img-wrap .selected-image img.default-img{
  opacity: 0;
}
.profile-wrapper .inner .inner-wrap .img-wrap:hover, .steps-form-wrapper .step  .img-wrap:hover {
  opacity: 0.7;
}

.profile-wrapper .inner .inner-wrap .img-wrap:hover > img, .steps-form-wrapper .step  .img-wrap:hover > img {
  opacity: 1;
  z-index: 1;
}

.profile-wrapper .inner .inner-wrap .img-wrap input, .steps-form-wrapper .step  .img-wrap input{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  top: 0;
  cursor: pointer;
}

.profile-wrapper .inner .inner-wrap .img-wrap .selected-image, .steps-form-wrapper .step  .img-wrap .selected-image{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profile-wrapper .inner .inner-wrap .img-wrap .selected-image img, .steps-form-wrapper .step  .img-wrap .selected-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-wrapper .inner .inner-wrap .right {
  width: calc(100% - 172px);
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.profile-wrapper .inner .inner-wrap .right .content {
  height: 100%;
  width: calc(100% - 332px);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap > img{
  width: 46px;
  margin-right: 10px;
}

.profile-wrapper .inner .inner-wrap .right .content h2 {
  margin-bottom: 16px;
}

.profile-wrapper .inner .inner-wrap .right .content p {
  color: #EBEBEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  max-width: 515px;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap {
  width: 294px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address, .collection-wrap .wallet-address{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
    /* border: 1px solid #55cbc6; */

  background: rgba(255, 255, 255, 0.05);
  padding: 11px 15px;
  width: 182px;
}
.collection-wrap .wallet-address{
  width: 100%;
}
.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address span, .collection-wrap .wallet-address p {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(100% - 25px);
  overflow: hidden;
  white-space: nowrap;
}
.collection-wrap .wallet-address img{
  width: auto !important;
  height: auto !important;
  border-radius: 0 !important;
}
.profile-wrapper .inner .profile-bottom {
  min-height: 64px;
  background: #3C3F47;
  display: flex;
  justify-content: flex-end;
    /* border: 1px solid #55cbc6; */

  border-radius: 0 0 10px 10px;
}

.profile-wrapper .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.profile-wrapper .tab-content>.tab-pane {
  display: none;
}

.profile-wrapper .tab-content>.active {
  display: block;
}

.profile-wrapper .fade.show {
  opacity: 1;
}



.profile-wrapper .nav-tabs {
  width: 100%;
  padding: 0 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-wrapper .nav-tabs li a {
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  transition: all ease-in-out 0.3s;
}

.profile-wrapper .nav-tabs li {
  margin-right: 30px;
}

.profile-wrapper .nav-tabs li:last-child {
  margin-right: 0;
}

.profile-wrapper .nav-tabs .nav-item.show .nav-link,
.profile-wrapper .nav-tabs .nav-item .nav-link:hover,
.profile-wrapper .nav-tabs .nav-link.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-wrapper .tab-content {
  margin-top: 70px;
}


/* MarketPlace */
.marketplace-banner img {
  min-height: 376px;
  object-fit: cover;
  width: 100%;
}

.marketplace-collection-wrap .inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -180px;
  margin-bottom: 25px;
  max-width: 1340px;
  margin: -180px auto 25px;
}


.marketplace-collection-wrap .inner ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 43px !important;
  width: 150px;
}

.marketplace-collection-wrap .inner ul:last-child {
  width: 100px;
}

.marketplace-collection-wrap .inner ul li {
  margin-right: 21px;
}

.marketplace-collection-wrap .inner ul li a {
  display: flex;
}

.marketplace-collection-wrap .inner ul li:last-child {
  margin-right: 0;
}

.marketplace-collection-wrap .inner .main-collection-info {
  margin: 0 auto;
}

.marketplace-collection-wrap .inner .main-collection-info>img {
  height: 230px;
  object-fit: cover;
  border-radius: 20px;
    /* border: 1px solid #55cbc6; */

  width: 230px;
  margin: 0 auto 20px;
}

.marketplace-collection-wrap .inner h2 {
  font-size: 28px !important;
  text-align: center;
  color: #fff;
  margin-bottom: 10px !important;
}

.marketplace-collection-wrap .inner p {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.marketplace-collection-wrap .inner p span {
  font-weight: 600;
}

.marketplace-collection-wrap .inner p img {
  margin-left: 16px;
  display: inline-block;
}

.marketplace-collection-wrap .inner h2 img {
  margin-left: 16px !important;
  display: inline-block;
}

.marketplace-collection-wrap .wrap {
  max-width: 1340px;
  margin: 0 auto;
}

.marketplace-collection-wrap .minting-now {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1D2833;
  border-radius: 5px;
  padding: 10px 30px;
  margin-bottom: 15px;
}

.marketplace-collection-wrap .minting-now p {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 15px !important;
}

.marketplace-collection-wrap .minting-now p button{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 4px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  padding: 5px 25px;
}

.marketplace-collection-wrap .minting-now p:first-child {
  padding-left: 43px;
  position: relative;
}

.marketplace-collection-wrap .minting-now p:first-child:before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background: #30DAFF;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}

.marketplace-collection-wrap .minting-now p:first-child:after {
  position: absolute;
  content: '';
  width: 26px;
  height: 26px;
  border: 4px solid rgba(48, 218, 255, 0.21);
  left: 0;
  top: 0;
  border-radius: 50%;
}

.marketplace-collection-wrap p span {
  font-weight: 600;
}

.marketplace-collection-wrap .desc p {
  font-size: 19px;
  line-height: 30px;
  text-align: center;
  color: #6D6D6D;
  text-align: center;
  max-width: 1246px;
  margin: 0 auto 30px !important;
}
.marketplace-collection-wrap .desc img{
  display: inline;
  position: relative;
  top: -2px;
  padding-left: 10px;
  cursor: pointer;
}
.marketplace-collection-wrap .collection-info-list {
  border-radius: 5px;
    /* border: 1px solid #55cbc6; */

  background: #3C3F47;
  display: flex;
  align-items: center;
  padding: 14px 0 10px;
  justify-content: center;
  margin-bottom: 35px;
}

.marketplace-collection-wrap .collection-info-list>div {
  padding: 0 5%;
  position: relative;
}

.marketplace-collection-wrap .collection-info-list>div:after {
  position: absolute;
  content: '';
  width: 1px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  height: 18px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.marketplace-collection-wrap .collection-info-list>div:last-child:after {
  display: none;
}

.marketplace-collection-wrap .collection-info-list p {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.marketplace-collection-wrap .collection-info-list span {
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 0.30);
  font-size: 18px;
  font-weight: 300;
}

.marketplace-collection-wrap .nft-wrapper {
  margin-bottom: 45px;
  background-image: none;
  padding: 0;
}

.marketplace-collection-wrap .filter-collection {
  display: flex;
  max-width: 100%;
  align-items: center;
}

.marketplace-collection-wrap .nft-wrapper form input,
.marketplace-collection-wrap .nft-wrapper form select {
  height: 43px;
  padding: 5px 18px;
  border-radius: 5px;
    /* border: 1px solid #55cbc6; */

  background: #3C3F47;
  color: rgba(255, 255, 255, 0.30);
  font-size: 15px;
  width: 100%;
}

.marketplace-collection-wrap .nft-wrapper form input {
  padding-left: 50px;
  background-image: url(./images/search-icon.png);
  background-size: 14px;
  background-position: 22px 50%;
  background-repeat: no-repeat;
}

.marketplace-collection-wrap .nft-wrapper form select {
  background-size: 13px;
  background-position: calc(100% - 22px) 50%;
  padding-right: 50px;
}

.marketplace-collection-wrap .nft-wrapper form .select-wrap:before {
  display: none;
}

.marketplace-collection-wrap .nft-wrapper form .select-wrap {
  width: 458px;
}

.marketplace-collection-wrap .nft-wrapper form>div {
  margin-bottom: 0;
  margin-right: 24px;
  width: calc(100% - 593px);
}

.marketplace-collection-wrap .nft-wrapper form>div:last-child {
  margin-right: 0;
}

.marketplace-collection-wrap .nft-wrapper form span {
  font-size: 16px;
  line-height: 1.1;
  color: #6D6D6D;
  display: inline-block;
  margin-right: 13px;
}

.multiple-collections-wrapper {
  max-width: 1120px;
  margin: 0 auto 30px;
}

.multiple-collections {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.multiple-collections .collection-box {
  width: calc(20% - 30px);
  margin: 0 15px;
  border-radius: 10px;
  background: #1D2833;
  margin-bottom: 30px;
}

.multiple-collections .collection-box .img-wrap {
  margin: 3px;
  margin-bottom: 12px;
  position: relative;
}

.multiple-collections .collection-box .img-wrap img {
  border-radius: 7px;
  width: 100%;
  object-fit: cover;
  height: 176px;
}

.multiple-collections .collection-box .img-wrap img.collection-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.multiple-collections .collection-box .collection-info {
  padding: 0 17px 10px;
}

.multiple-collections .collection-box .collection-info .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.multiple-collections .collection-box .collection-info h3 {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
}

.multiple-collections .collection-box .collection-info h6 {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  margin-top: 2px;
}

.multiple-collections .collection-box .collection-info .head span {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  display: inline-block;
    /* border: 1px solid #55cbc6; */

  border-radius: 30px;
  padding: 3px 8px;
  line-height: 1;
}

.multiple-collections .collection-box .collection-info p {
  font-weight: 300;
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  margin-bottom: 0 !important;
}

.multiple-collections .collection-box .collection-info>span {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #757272;
  display: block;
}

.multiple-collections .collection-box button {
  font-weight: 700;
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: center;
  color: #fff !important;
  border: 0 !important;
  display: block;
  background: #3C3F47 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0 !important;
  transition: all ease-in-out 0.2s;
  width: 100%;
  border-radius: 0 0 5px 5px !important;
}

.multiple-collections .collection-box button:hover {
  color: #fff !important;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
}

.multiple-collections .collection-box button img {
  margin-right: 4px;
}

.marketplace-collection-wrap>.container {
  padding: 0;
}

.nft-wrap,
.crypto-wrap {
  display: none;
  width: 100%;
}

.nft-wrap.selected,
.crypto-wrap.selected {
  display: block;
}

.detail-row form .checkbox-wrap input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.detail-row form .checkbox-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}

.checkbox-wrap label {
  position: relative;
  cursor: pointer;
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
  padding-left: 24px;
}

.checkbox-wrap img {
  position: absolute;
  left: 0;
  top: 4px;
}

.checkbox-wrap img.fill {
  display: none;
}



.checkbox-wrap input:checked+img {
  display: none;
}

.checkbox-wrap input:checked+img+img.fill {
  display: inline-block;
}


/* Sell Page */
.sell-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 20px auto 0;
}

.sell-wrap .img-wrap {
  width: 48%;
}

.sell-wrap .img-wrap img {
  width: 100%;
  border-radius: 3px;
}

.sell-wrap .content {
  width: 48%;
}

.sell-wrap .content span {
  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 24px;
}

.sell-wrap .content h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
}

.sell-wrap .item-interaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.sell-wrap .item-interaction>div {
  display: flex;
  gap: 10px;
}

.sell-wrap .item-interaction .item-views span {
  padding: 7px 14px;
  background: #1D2833;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  margin: 0;
}

.sell-wrap .item-interaction>div button {
  background: #1D2833;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;

}

.sell-wrap .content p {
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 24px;
}

.sell-wrap .content ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 24px;
}

.sell-wrap .content ul li {
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 5px;
}

.sell-wrap .content>a {
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 40px;
  text-decoration: underline;
  transition: all ease-in-out 0.2s;
  display: inline-block;
}

.sell-wrap .content>a:hover {
  opacity: 0.9;
}

.sell-wrap .content .form-wrap form {
  width: 100%;
  background: transparent;
  padding: 0;
}

.sell-wrap .content .form-wrap form input {
  margin-bottom: 24px;
}

.form-wrap form .checkbox-wrapper {
  display: flex;
  align-items: center;
}

.form-wrap form .checkbox-wrapper label {
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
}

.form-wrap form .checkbox-wrapper input {
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  background: #3C3F47 !important;
}

.form-wrap form .checkbox-wrapper input[type='checkbox']:checked {
  background-image: url(images/checked.png) !important;
  background-size: 15px 12px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.react-responsive-modal-modal.microeconomy-modal.execute-proposal-modal .modal-body {
  text-align: left;
}

.react-responsive-modal-modal.microeconomy-modal.execute-proposal-modal .modal-body p {
  margin-bottom: 20px;
}

.react-responsive-modal-modal.microeconomy-modal.execute-proposal-modal .modal-body label {
  font-weight: 400;
  margin: 8px 0 20px;
}

.microeconomy-modal.execute-proposal-modal .form-wrap {
  max-width: 100%;
}

.microeconomy-modal.execute-proposal-modal .info-box-wrap {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 24px;
}

.microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box {
  width: 31%;
  border-radius: 8px;
    /* border: 1px solid #55cbc6; */

  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
}

.microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box h4 {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box p {
  margin: 0;
  color: #fff;
}

.proposals-table button {
  border-radius: 2px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 8px;
}
.proposals-table .vote-reject button {
  width: 60px;
}
.proposals-table button.execute {
  width: 100px;
}
.proposals-table.table-wrapper table thead th:nth-child(5) {
  width: 290px;
}

.proposals-table.table-wrapper table thead th:nth-child(6) {
  width: 180px;
}

.dao-address {
  margin-bottom: 20px;
}

.dao-address p {
  color: #fff;
  font-weight: 300;
}

.dao-address p a {
  color: #fff;
  font-weight: 300;
}

/* Steps Modal */
body {
  overflow: visible !important;
}

.react-responsive-modal-root{
 opacity: 0;
 animation: fadeAnimation 0.3s ease-in-out forwards;
 animation-delay: 2s; 
}

.react-responsive-modal-root.confirmation-modal-root{
  z-index: 999999 !important;
}

@keyframes fadeAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

.react-responsive-modal-root.custom-modal-root {
  width: 368px;
  height: auto;
  bottom: auto;
  left: auto;
  top: 120px;
  right: 50px;
  position: absolute;
  opacity: 1;
  transition: all ease-in-out 0.2s;
}

.react-responsive-modal-overlay.custom-overlay+.react-responsive-modal-container {
  width: 368px;
  height: auto;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal {
  margin: 0;
  max-width: 100%;
  padding: 20px !important;
  border: 0 !important;
  border-radius: 5px !important;
  background: #1D2833 !important;
}

.steps-head {
  max-width: calc(100% - 25px);
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body h3 {
  font-size: 20px;
  text-align: left;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body i {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: block;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body p {
  margin-bottom: 20px;
  font-size: 14px;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body p span {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.react-responsive-modal-modal.microeconomy-modal.steps-modal .modal-body img {
  margin: 0;
}

.react-responsive-modal-modal.microeconomy-modal.confirmation-modal{
  z-index: 99999;
  width: 450px !important;
  vertical-align: top;
}

.microeconomy-modal.steps-modal .steps-btns button:last-child,
.microeconomy-modal.confirmation-modal .confirmation-btns button:last-child{
  display: inline-block;
}

.microeconomy-modal.steps-modal .steps-btns,
.microeconomy-modal.confirmation-modal .confirmation-btns{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.microeconomy-modal.confirmation-modal .confirmation-btns{
  max-width: 300px;
  margin: 20px auto 0;
}

.microeconomy-modal.steps-modal .steps-btns button,
.microeconomy-modal.confirmation-modal .confirmation-btns button{
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  padding: 6px 17px;
  transition: all ease-in-out 0.2s;
  width: 136px;
}

.microeconomy-modal.steps-modal .steps-btns button:hover,
.microeconomy-modal.confirmation-modal .confirmation-btns button:hover{
  opacity: 0.85;
}

.microeconomy-modal.steps-modal .steps-btns button:disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.launchdao-head{
  display: flex;
  align-items: flex-end;
  background: #1D2833;
  padding: 40px 24px;
  justify-content: space-between;
  margin-top: 30px;
}

.launchdao-head{
  display: flex;
  align-items: flex-end;
  background: #1D2833;
  padding: 40px 24px;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 16px;
}

.launchdao-head p{
  color: #A9AAAD;
  font-size: 15px;
}

.launchdao-head button{
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  padding: 10px 30px;
  color: #fff;
  display: flex;
  align-items: center;
}

.launchdao-head button img{
  margin-left: 16px;
}

.launchdao-steps-boxes-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

.launchdao-steps-boxes-wrap .step-box{
  width: calc(33.33% - 16px);
  margin: 8px;
  background-color: #1D2833;
  padding: 16px;
  display: flex;
}

.launchdao-steps-boxes-wrap .step-box .icon-wrap{
  background: #222F3B;
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchdao-steps-boxes-wrap .step-box .icon-wrap img{
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.launchdao-steps-boxes-wrap .step-box .step-box-info{
  width: calc(100% - 96px);
  padding-left: 12px;
}

.launchdao-steps-boxes-wrap .step-box .step-box-info span{
  color: #A9AAAD;
  font-size: 15px;
  display: block;
  margin-bottom: 16px;
}

.launchdao-steps-boxes-wrap .step-box .step-box-info h3{
  color: #fff;
  font-size: 20px;
}

.progress-bar{
  background: #343E47;
  width: 100%;
  height: 12px;
}
.progress-bar-fill{
  background: linear-gradient(108.23deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
  height: 100%;
}

.steps-form-wrapper{
  background: #1D2833;
  padding: 32px 24px 40px;
  position: relative;
  margin-bottom: 72px;
}

.progress-head{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 7px;
}

.progress-head h3{
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
}

.progress-head span{
  color: #A9AAAD;
  font-size: 15px;
}

.steps-form-wrapper .step h2{
  margin-bottom: 16px !important;
}

.steps-form-wrapper .step > p{
  color: #A9AAAD;
  font-size: 15px;
  line-height: 1.4;
  margin-bottom: 30px;
}

.steps-form-wrapper .step .btn-wrap{
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -72px;
}

.steps-form-wrapper .step .btn-wrap .btn{
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 44px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 35px;
}

.steps-form-wrapper .step .btn-wrap .btn.back{
  border: 1px solid #55cbc6 !important;
}

.steps-form-wrapper .step .btn-wrap .btn.back img{
  margin-right: 10px;
}

.steps-form-wrapper .step .btn-wrap .btn.next{
  background: linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
}

.steps-form-wrapper .step .btn-wrap .btn.next img{
  margin-left: 10px;
}

.step .form-wrap.dir-col{
  flex-direction: column;
  max-height: 274px;
}
.step .form-wrap.dir-col.no-height{
  max-height: initial;
}
.step .form-wrap{
  margin: 24px -6px -6px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  flex-wrap: wrap;
}
.step .form-wrap.full-width{
  display: block;
  margin: 0;
}

.step .form-wrap.full-width >div{
  width: 100%;
  margin: 0;
}
.step .form-wrap.full-width >div textarea{
  height: 250px;
}
.step .form-wrap >div{
  width: calc(33.33% - 12px);
  margin: 6px;
}

.step .form-wrap.full-width>div{
  width: 100%;
  margin: 0 0 12px;
}
.step .form-wrap input, .step .form-wrap textarea, .step .form-wrap select{
  line-height: 28px;
}

.step .checkbox-area.form-group{
  width: 100%;
  margin-bottom: 16px;
}
.step .form-wrap >div label{
  color: #A9AAAD;
}

.step .form-wrap >div.addmore button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 12px 25px;
}

.step .form-wrap >div.addmore button img{
  margin-left: 10px;
}

.step .form-wrap >div.swap .form-group .input-group{
  background: #3C3F47;
  border: 0;
  height: 70px;
}
.step .form-wrap .network-dropdown select{
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
  background-image: url("./images/select-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 24px;
}
.step .checkbox-area.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.step .checkbox-area.form-group label {
  position: relative;
  cursor: pointer;
  color: #A9AAAD;
  font-size: 15px;
}

.step .checkbox-area.form-group label:before {
  content:'';
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #55cbc6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
}

.step .checkbox-area.form-group input:checked + label:before {
  background : linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
}


.step .checkbox-area.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 8px;
  width: 6px;
  height: 12px;
  border: solid #1D2833;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.step .form-wrap .filewrap .upload-btn{
  background : linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  padding: 12px 20px;
  right: 15px;
}

.step .radio-wrap{
  width: 400px;
  max-width: 100%;
  border-radius: 3px;
  border: 1px solid #55cbc6;
}

.step .radio-wrap p{
  width: 50%;
}

.step .radio-wrap label{
  border-radius: 3px !important;
  padding: 8px 12px !important;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500 !important;
}

.step .radio-wrap input[type="radio"]:checked+label{
  background : linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
}

.radio-select input[type="radio"]{
    position: absolute;
    left: -9999px;
}
.radio-select p{
  margin-bottom: 12px;
}

.radio-select p:last-child{
  margin-bottom: 0;
}
.radio-select input[type="radio"]+ label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #A9AAAD;
    font-size: 15px;
    z-index: 1;
}

.radio-select input[type="radio"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: transparent;
    border: 1px solid #55cbc6;
}
.radio-select input[type="radio"]:checked + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background : linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

/* Project Detail Main Page */
.project-detail-header .container{
  max-width: 1300px;
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: space-between;
}

.project-detail-header a.logo{
  display: flex;
  align-items: center;
}

.project-detail-header a.logo span{
  font-weight: 600;
}

.project-detail-header a.logo img{
  margin-right: 7px;
}

.project-detail-header ul{
  display: flex;
  align-items: center;
  gap: 40px;
}

.project-detail-header ul li a{
  font-weight: 600;
  text-transform: capitalize;
}

.project-detail-header a.launch-app{
  background: #000000;
  border-radius: 30px;
  padding: 12px 25px;
  color: #fff;
  text-transform: capitalize;
}

.project-detail-content{
  padding-top: 50px;
}

.project-detail-content .container{
  max-width: 1180px;
  padding: 0 20px;
}

.project-detail-content .video-wrapper{
  margin-bottom: 50px;
}

.project-detail-content .video-wrapper video{
 width: 100%;
 border-radius: 5px;
}

.project-detail-content h1{
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 20px;
}

.project-detail-content h3{
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
}

.project-detail-content p{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
}

.project-detail-content .intro img{
  width: 100%;
  margin-bottom: 30px;
}

.project-detail-content h2{
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.project-detail-content .links{
  padding-bottom: 100px;
}

.project-detail-content .links .inner{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.project-detail-content .links .inner .link{
  width: 48px;
  border-radius: 50%;
}

.project-detail-content .links .inner .link img{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.project-detail-content .team{
  padding: 100px 0 80px;
  position: relative;
}

.project-detail-content .team img.bg-gradient{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
}
.project-detail-content .team .inner{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-detail-content .team .inner .box{
  width: 25%;
  text-align: center;
  padding: 20px;
}

.project-detail-content .team .inner .box .img-wrap{
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 15px;
  position: relative;
}

.project-detail-content .team .inner .box img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.project-detail-content .team .inner .box img.linkedin{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.project-detail-content .team .inner .box h3{
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.project-detail-content .team .inner .box span{
  display: block;
  color: rgba(0,0,0,0.5);
  font-weight: 700;
}

.project-detail-content .document{
  padding-bottom: 80px;
  position: relative;
}

.project-detail-content .document img.bg-gradient{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  transform: rotate(180deg);
}

.project-detail-content .document .inner{
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.project-detail-content .document .inner .box{
  width: 33.33%;
  text-align: center;
  padding: 20px;
}

.project-detail-content .document .inner .box .img-wrap{
  width: 33.33%;
  text-align: center;
  width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 10px 10px 60px #EDF4FF;
  border-radius: 50%;
  margin: 0 auto 15px;
}

.project-detail-content .document .inner .box  h3{
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.project-detail-info-box{
  display: flex;
  flex-wrap: wrap;
  margin: -20px;
}

.project-detail-info-box .box{
  width: calc(50% - 40px);
  margin: 20px;
  background: #22282E;
  border-radius: 5px;
  padding: 25px;
}

.project-detail-info-box .box h3{
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
}

.project-detail-info-box .box.vesting-ingo-box{
  width: 100%;
  margin: 20px;
}

.project-detail-info-box .box.token-metrics .apexcharts-legend .apexcharts-legend-series:first-child{
  display: none;
}

.project-detail-info-box .box.token-metrics .apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text{
  color: #fff !important;
  font-size: 14px !important;
}

.project-detail-info-box .box.token-metrics .apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text span{
  color: rgba(255,255,255,0.4) !important;
}

.project-detail-info-box .box.token-metrics .apexcharts-legend .apexcharts-legend-series .apexcharts-legend-marker{
  border-radius: 4px !important;
  margin-right: 7px !important;

}

.project-detail-info-box .box.token-metrics .apexcharts-legend.apexcharts-align-center{
  justify-content: center;
}

.project-detail-info-box .box.token-metrics .apexcharts-legend .apexcharts-legend-series{
  margin-top: -30px !important;
}

.project-detail-info-box .box .input-wrap{
  position: relative;
  margin-top: 20px;
}

.project-detail-info-box .box .input-wrap input{
  background: #343E47;
  border-radius: 5px;
  color: #A9AAAD;
  font-size: 15px;
  position: relative;
  border: 0;
  outline: 0;
  padding: 18px 125px 18px 93px;
  width: 100%;
}

.project-detail-info-box .box .input-wrap .xdc{
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  top: 19px;
  left: 20px;
}

.project-detail-info-box .box .input-wrap .xdc img{
  display: flex;
  align-items: center;
  width: 20px;
  margin-right: 7px;
}

.project-detail-info-box .box .input-wrap > img{
  position: absolute;
  top: 20px;
  right: 0;
  cursor: pointer;
}

.project-detail-info-box .box .input-wrap > img.meta-mask{
  right: 90px;
}

.project-detail-info-box .box .input-wrap > img.copy{
  right: 55px;
}

.project-detail-info-box .box .input-wrap > a.link{
  position: absolute;
  right: 20px;
  top: 19px;
}

.project-detail-info-box .box p{
  color: #BDBEC0;
}

.project-detail-info-box .box .vesting-info{
  display: flex;
  align-items: flex-start;
}

.project-detail-info-box .box .vesting-info .date-wrap{
  width: 96px;
  border-radius: 5px;
  overflow: hidden;
  background: #343E47;
}

.project-detail-info-box .box .vesting-info .date-wrap span{
  display: block;
  text-align: center;
  background: linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 0;
}

.project-detail-info-box .box .vesting-info .date-wrap .date{
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  padding: 15px 10px;
}

.project-detail-info-box .box .vesting-info .right-info{
  width: calc(100% - 96px);
  padding-left: 40px;
}

.project-detail-info-box .box .vesting-info .right-info .head{
  display: flex;
  justify-content: space-between;
}

.project-detail-info-box .box .vesting-info .right-info h6{
  color: #fff;
  font-weight: 700;
}

.project-detail-info-box .box .vesting-info .right-info .vesting-length{
  text-align: right;
}

.project-detail-info-box .box .vesting-info .right-info span img{
  margin-right: 5px;
}

.project-detail-info-box .box .vesting-info .right-info span{
  display: flex;
  align-items: center;
  color: #A9AAAD;
  margin: 5px 0;
}

.project-detail-info-box .box .vesting-info .right-info span:last-child{
  font-size: 14px;
}

.project-detail-info-box .box .vesting-info .right-info .vesting-length span{
  justify-content: flex-end;
}

.project-detail-info-box .box .vesting-info .right-info  .cifi-length-box{
  display: flex;
  gap: 40px;
}

.project-detail-info-box .box .vesting-info .right-info .progress-bar-wrap{
  position: relative;
  margin: 5px 0 30px;
}
.project-detail-info-box .box .vesting-info .right-info .progress-bar-wrap >div>div{ 
  background: #343E47 !important;
  border-radius: 0 !important;
}

.project-detail-info-box .box .vesting-info .right-info .progress-bar-wrap >div>div>div{ 
  background: linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%) !important;
  border-radius: 0 !important;
}

.project-detail-info-box .box .vesting-info .right-info .progress-bar-wrap >div span{
  position: absolute;
  bottom: -42px !important;
  left: -5px !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.no-data{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
  padding: 100px 20px;
  background: #fff;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #55CBC6;
  text-align: center;
  z-index: 2;
}

@media screen and (min-width:1750px) {
  .react-responsive-modal-overlay.custom-overlay {
    display: none;
  }

  .react-responsive-modal-overlay.custom-overlay.show {
    display: block;
  }
}

@media screen and (max-width:1820px) {
  .nft-collection .collection-wrap .box {
    width: calc(33.33% - 24px);
  }

  .nft-collection .collection-wrap .box .btn-wrap button:not(:last-child) {
    margin-right: 5px;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    padding: 0 4%;
  }
}

@media screen and (max-width:1749px) {
  .react-responsive-modal-root.custom-modal-root {
    position: fixed;
    max-width: 368px;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    top: 0;
    bottom: 0;
    display: flex;
  }

  .react-responsive-modal-overlay.custom-overlay+.react-responsive-modal-container {
    width: 100%;
  }
}

@media screen and (max-width:1600px) {
  .marketplace-collection-wrap .minting-now p {
    font-size: 16px;
    margin: 0 5px !important;
  }

  .tabs-wrapper ul li a {
    padding: 16px 3px;
  }

  .trading-main-wrapper .tabs-wrapper {
    width: calc(100% - 350px);
  }

  .trading-main-wrapper .tabs-wrapper:nth-child(even) {
    width: 350px;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    padding: 0 3.5%;
  }
}

@media screen and (max-width:1439px) {
  .buy-vip-nft{
    font-size: 14px;
    padding: 11px;
    margin-right: 10px;
  }

  .nft-collection .collection-wrap .box>a>img,
  .nft-collection .collection-wrap .box>img {
    height: 370px;
  }

  .multiple-collections .collection-box {
    width: calc(25% - 30px);
  }

  .chart-wrapper .bar-chart-wrapper {
    margin-bottom: 30px;
  }

  .dashboard .chart-wrapper .radial-chart-wrapper .inner {
    height: auto;
  }

  .dashboard .chart-wrapper .bar-chart-head {
    min-height: initial;
  }

  .nft-collection .collection-wrap .box {
    width: calc(50% - 24px);
    padding: 20px;
  }

  .microeconomy-wrapper {
    padding: 0 30px;
  }

  .chart-wrapper {
    flex-wrap: wrap;
  }

  .chart-wrapper .radial-chart-wrapper {
    width: 622px;
    margin-left: 0;
  }

  .dashboard-pool-table,
  .borrow-table {
    width: 1070px;
  }

  .stability-pool-table {
    min-width: 1020px;
  }

  .trading-main-wrapper {
    margin-left: -31px;
  }

  .trading-main-wrapper .tabs-wrapper {
    width: 100%;
  }

  .trading-main-wrapper .tabs-wrapper:nth-child(even) {
    width: 100%;
  }

  .select-market .form-wrap {
    padding-bottom: 30px;
  }

  .table-info.tabs-wrapper .table-wrap {
    padding-bottom: 30px;
    overflow: auto;
  }

  .table-info.tabs-wrapper .table-wrap table {
    min-width: 700px;
  }

  .price-depth .tab-content .tab-pane,
  #chart-candlestick {
    height: 300px;
  }

  .book-trades .table-wrap {
    padding-bottom: 20px;
  }
}

@media screen and (max-width:1365px) {
  .right-main-content .buy-vip-nft{
    margin: 0 auto 16px;
  }

  .right-main-content .microeconomy-head .btn-wrap {
    margin: 0 auto;
  }

  .right-main-content .microeconomy-head {
    flex-direction: column;
  }

  .right-main-content .microeconomy-head .logo-wrap {
    margin-bottom: 20px;
  }

  .nft-collection.circularitydao-stakenft .collection-wrap .box>a>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box .box-inner>img{
    height: 370px;
  }

  .nft-collection .collection-wrap .box>a>img,
  .nft-collection .collection-wrap .box>img {
    height: 340px;
  }

  .marketplace-collection-wrap .minting-now {
    padding: 10px 15px;
  }

  .marketplace-collection-wrap .minting-now {
    flex-wrap: wrap;
  }

  .marketplace-collection-wrap .minting-now p {
    width: 33.33%;
    margin: 5px 0 !important;
    padding: 0 10px;
  }

  .marketplace-collection-wrap .minting-now p:first-child:before {
    left: 44px;
  }

  .marketplace-collection-wrap .minting-now p:first-child:after {
    left: 40px;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    padding: 0 25px;
  }

  .marketplace-collection-wrap .collection-info-list p {
    font-size: 20px;
  }

  .marketplace-collection-wrap .collection-info-list span {
    font-size: 17px;
  }

  .marketplace-collection-wrap .nft-wrapper form .select-wrap {
    width: 328px;
  }

  .marketplace-collection-wrap .nft-wrapper form>div {
    width: calc(100% - 463px);
  }

  .marketplace-collection-wrap .minting-now p:first-child {
    padding: 0 10px;
  }

  .profile-wrapper .inner .inner-wrap .right {
    flex-direction: column;
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 32px;
  }

  .profile-wrapper .inner .profile-bottom {
    margin-top: 0;
  }

  .profile-wrapper .inner .inner-wrap .right .content {
    padding-bottom: 20px;
    width: 100%;
  }

  .dao-genesis .microeconomy-head {
    flex-direction: column;
  }

  .dao-genesis .microeconomy-head .btns {
    margin: 0 auto 20px;
  }

  .dao-genesis .microeconomy-head .logo-wrap {
    margin-bottom: 30px;
  }

  .dao-genesis .detail-row .detail-list {
    grid-template-columns: 1fr;
  }

  .view-all .filters {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width:1199px) {
  
  .nft-collection .collection-wrap .box>a>img,
  .nft-collection .collection-wrap .box>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box>a>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box .box-inner>img {
    height: 300px;
  }

  .marketplace-collection-wrap .desc p {
    font-size: 18px;
  }

  .marketplace-collection-wrap .collection-info-list {
    flex-wrap: wrap;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    width: 33.33%;
    margin: 15px 0;
  }

  .marketplace-collection-wrap .collection-info-list>div:nth-of-type(3)::after {
    display: none;
  }

  .marketplace-collection-wrap .nft-wrapper form .select-wrap {
    width: 248px;
  }

  .marketplace-collection-wrap .nft-wrapper form>div {
    width: calc(100% - 383px);
  }

  .multiple-collections .collection-box {
    width: calc(33.33% - 30px);
  }

  .multiple-collections .collection-box .img-wrap img {
    height: 185px;
  }

  .marketplace-collection-wrap .minting-now p:first-child:after {
    left: 26px;
  }

  .marketplace-collection-wrap .minting-now p:first-child:before {
    left: 30px;
  }

  .submit-proposal.view-all .filters {
    grid-template-columns: 2fr 1fr;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .apexcharts-legend {
    width: 220px;
  }

}

@media screen and (max-width:1100px) {
  .buy-vip-nft{
    margin: 0 auto 16px;
  }

  .microeconomy-head .btn-wrap {
    margin: 0 auto;
  }

  .microeconomy-head {
    flex-direction: column;
  }

  .microeconomy-head .logo-wrap {
    margin-bottom: 20px;
  }
}
@media screen and (min-width:1023px) {
  .sidebar {
    height: 100vh;
    position: fixed;
    z-index: 1;
    overflow-y: auto;
  }

}

.right-main-closed {
  /* mini sidebar */
  /* width: 100%; */
  width: calc(100% - 106px);
  margin-left: auto;
  transition: all .2s ease-in-out;
}

.sidebar {
  position: fixed;
  left: -290px;
  transition: all ease-in-out 0.3s;

}

@media screen and (max-width:1023px) {
  .sidebar {
    top: 0;
    height: 100vh;
    overflow: auto;
    z-index: 10;
  }
  .bg-open {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
  }

  .bg-open.active {
    display: block;
  }

  .right-main-content {
    width: 100%;
    margin-left: auto;
  }

  .right-main-closed {
    width: 100%;
    margin-left: auto;
    transition: all .2s ease-in-out;
  }


}

@media screen and (min-width:1023px) {

.sidebar {
  left: 0px !important;

}

.minibar{ 
  transition: all .2s ease-in-out;
  display: none;
}

.sidebar-arrow{
  left: 75px;
}
}

.minibar{
  display: none !important;
  transition: all .2s ease-in-out;
}

  .sidebar.open {
    left: 0;
  }

  .sidebar-arrow {
    display: flex;
  }

  

  .dashboard table thead th {
    padding: 0 18px 15px;
  }

  .trading-main-wrapper {
    margin-left: 0;
  }


@media screen and (max-width:991px) {

  .dashboard-pool-table,
  .borrow-table {
    width: 970px;
  }

  .staking-table {
    min-width: 900px;
  }

  .borrow-manage-wrap {
    flex-wrap: wrap;
  }

  .borrow-manage-wrap .left,
  .borrow-manage-wrap .right {
    width: 100%;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .apexcharts-legend {
    width: auto;
  }

  .borrow-manage-wrap .info-box {
    margin-bottom: 30px;
  }
}


@media screen and (max-width:767px) {
  
  .ilo-invest .token-list ul li .left {
    width: 40%;
  }
  .ilo-invest .token-list ul li .right{
    width: 56%;
  }

  .profile-wrapper .nav-tabs {
    padding: 0 20px;
  }

  .sell-wrap {
    flex-direction: column;
  }

  .sell-wrap .img-wrap {
    max-width: 400px;
    margin-bottom: 40px;
    width: 100%;
  }


  .sell-wrap .content {
    width: 100%;
  }

  .nft-collection .collection-wrap .box>a>img,
  .nft-collection .collection-wrap .box>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box>a>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box>img,
  .nft-collection.circularitydao-stakenft .collection-wrap .box .box-inner>img {
    height: auto;
  }

  .marketplace-collection-wrap .inner {
    flex-direction: column;
    align-items: center;
  }

  .marketplace-collection-wrap .inner .main-collection-info {
    order: 1;
  }

  .marketplace-collection-wrap .inner ul:last-child {
    order: 2;
    margin: 30px 0 !important;
  }

  .marketplace-collection-wrap .inner ul {
    order: 3;
  }

  .marketplace-collection-wrap .minting-now p {
    width: 50%;
    margin: 20px 0 !important;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    padding: 0 20px;
  }

  .marketplace-collection-wrap .collection-info-list p {
    font-size: 18px;
  }

  .marketplace-collection-wrap .collection-info-list span {
    font-size: 16px;
  }

  .marketplace-collection-wrap .filter-collection {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .marketplace-collection-wrap .nft-wrapper form .select-wrap {
    width: 100%;
    margin-top: 15px;
  }

  .marketplace-collection-wrap .nft-wrapper form>div {
    width: calc(100% - 120px);
    margin-right: 0;
  }

  .multiple-collections .collection-box {
    width: calc(50% - 30px);
  }

  .multiple-collections .collection-box .img-wrap img {
    height: 240px;
  }

  .profile-wrapper .nav-tabs {
    flex-wrap: wrap;
  }

  .profile-wrapper .nav-tabs li {
    width: calc(33.33% - 20px);
    flex-grow: 1;
    margin: 10px;
    text-align: center;
  }

  .modules-list {
    grid-template-columns: 1fr 1fr;
  }

  .nft-collection .head {
    flex-direction: column;
    align-items: flex-start;
  }

  .initial-offer-wrap .form-wrap .inner form .form-inner {
    width: 100%;
  }

  .microeconomy-tool .inner .box {
    width: 49%;
  }

  .nft-collection .collection-wrap .box,
  .nft-collection.circularitydao-stakenft .collection-wrap .box {
    width: 100%;
  }

  .detail-row .dao-info {
    grid-template-columns: 1fr;
  }

  .detail-row .dao-info::after {
    display: none;
  }

  .trading-main-wrapper .tabs-wrapper ul {
    flex-wrap: wrap;
  }

  .trading-main-wrapper .tabs-wrapper ul li {
    width: 50%;
    flex-grow: 1;
    margin-bottom: -1px;
  }

  .trading-main-wrapper .tabs-wrapper ul li a {
    border-bottom: 1px solid #55cbc6;
  }

  .user-profile-icon {
    margin: 0 auto 20px;
  }
  .microeconomy-head .btn-wrap button:first-child{
    margin: 0;
  }

  .microeconomy-head .btn-wrap {
    flex-direction: column;
  }

  .microeconomy-head .btn-wrap .select-wrap {
    margin-bottom: 15px;
  }
  .balance-wrap{
    margin: 0;
  }
}

@media screen and (max-width:640px) {
  .form-wrap form .input-wrap .form-group{
    width: 48.5%;
  }
  .submit-proposal.view-all .filters {
    grid-template-columns: 1fr;
  }

  .react-responsive-modal-modal.microeconomy-modal.add-liquidity .modal-body .input-wrapper .form-group {
    width: 100%;
  }

  .min-prices-wrapper .form-group-wrapper {
    width: 100%;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .apexcharts-legend {
    width: 220px;
  }
}

@media screen and (max-width:575px) {

  .balance-wrap{
    flex-wrap: wrap;
    justify-content: center;
  }

  .microeconomy-head .btn-wrap button:first-child{
    width: calc(100% - 50px);
    justify-content: center;
  }

  .microeconomy-head .btn-wrap button{
    margin: 0;
  }

  .microeconomy-head .btn-wrap button:last-child{
    margin-top: 15px;
  }

  .microeconomy-modal.execute-proposal-modal .info-box-wrap {
    flex-direction: column;
  }

  .microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box {
    width: 100%;
    margin-bottom: 16px;
  }

  .microeconomy-modal.execute-proposal-modal .info-box-wrap .info-box:last-child {
    margin-bottom: 0;
  }

  .marketplace-collection-wrap .inner {
    margin-top: -110px;
  }

  .marketplace-collection-wrap .inner {
    margin-top: -110px;
  }

  .marketplace-collection-wrap .minting-now p {
    width: 100%;
  }

  .marketplace-collection-wrap .minting-now p:first-child {
    width: 150px;
    margin: 20px auto !important;
    display: block;
  }

  .marketplace-collection-wrap .minting-now p:first-child:after {
    left: -10px;
  }

  .marketplace-collection-wrap .minting-now p:first-child:before {
    left: -6px;
  }

  .marketplace-collection-wrap .collection-info-list>div {
    width: 50%;
    padding: 0 15px;
  }

  .marketplace-collection-wrap .collection-info-list>div:nth-of-type(3)::after {
    display: inline-block;
  }

  .marketplace-collection-wrap .collection-info-list>div:nth-of-type(even)::after {
    display: none;
  }

  .multiple-collections .collection-box {
    width: 100%;
    max-width: 280px;
  }

  .multiple-collections .collection-box .img-wrap img {
    height: auto;
  }

  .multiple-collections {
    justify-content: center;
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 20px;
    flex-direction: column;
  }

  .profile-wrapper .inner .inner-wrap .img-wrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-wrapper .nav-tabs li {
    width: calc(50% - 20px);
  }

  .profile-wrapper .inner .inner-wrap .right {
    padding-left: 0;
    width: 100%;
  }

  .view-all .filters .btns {
    flex-wrap: wrap;
  }

  .view-all .filters .btns button {
    width: 100%;
  }

  .modules-list {
    grid-template-columns: 1fr;
  }

  .microeconomy-wrapper {
    padding: 0 20px;
  }

  .dashboard .chart-wrapper .bar-chart-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-wrapper .radial-chart-wrapper .inner,
  .chart-wrapper .bar-chart-wrapper .inner {
    padding: 0;
  }

  .chart-wrapper .radial-chart-wrapper .inner {
    min-height: 170px;
  }

  .nft-collection .collection-filters {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .nft-collection .collection-filters .toggle-wrap {
    width: 100%;
  }

  .nft-collection .collection-filters .toggle-wrap label {
    justify-content: flex-start;
  }

  .chart-wrapper .bar-chart-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .tabs-wrapper-ilo .nav-tabs .nav-item .nav-link {
    font-size: 14px;
  }

  .tabs-wrapper-ilo .nav-tabs li {
    margin-right: 13px;
  }

  .tabs-wrapper-ilo .nav-tabs li:last-child {
    margin-right: 0;
  }

  .initial-offer-wrap .form-wrap .inner form {
    padding-left: 24px;
  }

  .swap .form-wrapper {
    padding: 24px;
  }

  .swap .form-group .input-group {
    padding: 8px 12px 8px 12px;
  }

  .microeconomy-tool .inner .box {
    width: 100%;
  }

  .form-wrap form input,
  .form-wrap form textarea {
    padding: 20px;
  }

  .form-wrap form .filewrap .upload-btn {
    right: 20px;
  }

  .form-wrap form .filewrap {
    padding: 20px;
  }

  .microeconomy-wrapper .form-wrap form {
    padding-left: 24px;
  }
  .microeconomy-wrapper.blockfund .form-wrap form{
    padding: 48px 24px;
  }
  .sample-csv-file {
    display: none;
  }

  .microeconomy-head .btns button {
    padding: 12px 18px;
  }

  .form-wrap form .form-group.search input {
    padding-left: 35px;
  }

  .react-responsive-modal-modal.microeconomy-modal .modal-body h3 {
    font-size: 20px;
  }

  .react-responsive-modal-modal.microeconomy-modal .modal-body .form-head p {
    font-size: 20px;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner {
    padding: 0;
  }

  .react-responsive-modal-modal.microeconomy-modal.borrow-manage-eth-modal {
    padding: 15px 20px 40px !important;
  }
}

@media screen and (max-width:480px) {
  .form-wrap form .input-wrap .form-group{
    width: 100%;
  }
}

@media screen and (max-width:440px) {

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner>div {
    height: 100% !important;
    min-height: 200px !important;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner>div>div {
    height: 100% !important;
    min-height: 200px !important;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner div foreignObject {
    min-height: 200px !important;
    height: 200px !important;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .inner div svg {
    min-height: 200px !important;
  }

  .microeconomy-modal .borrow-manage-form .form-wrap form {
    padding: 30px 20px;
  }

  .borrow-manage-wrap .chart-wrapper .radial-chart-wrapper .apexcharts-legend {
    width: 160px;
  }

  .borrow-manage-wrap .chart-wrapper .apexcharts-inner {
    transform: translate(10px, 50px) !important;
  }

}

.Toastify__toast{
  background-color: #1D2833 !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 6px rgba(255,255,255,0.05) !important;
}

.Toastify__toast .Toastify__toast-body >div{
  color: rgba(255,255,255,0.8);
}

.Toastify__toast .Toastify__close-button > svg{
  color: rgba(255,255,255,0.8);
  fill: rgba(255,255,255,0.8);
}

.Toastify__toast .Toastify__close-button{
  opacity: 1;
}

.loader .loader-area img{
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 30px;
  animation: zoominoutsinglefeatured 1.5s infinite ;
}

@keyframes zoominoutsinglefeatured {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.3,1.3);
  }
  100% {
      transform: scale(1,1);
  }
}



/* veriff */

.form-wrap .veriff-wrap form input[type="submit"]{
  background: linear-gradient(108deg, #30BD97 -60.82%, #6EB3D6 150.65%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  padding: 12px 15px;
  transition: all ease-in-out 0.2s;
  border: 0 !important;
  cursor: pointer;
}
.veriff-wrap .veriff-description{
  margin-bottom: 0;
  color: #9B9B9B;
}
.veriff-wrap .veriff-description a{
  font-weight: 500;
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%) !important;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.kyc-kyb-tabs .nav-tabs, .airdrop-tabs .nav-tabs {
  display: flex;
  border-bottom: 1px solid #55CBC6;
}
.kyc-kyb-tabs .fade, .airdrop-tabs .fade {
  opacity: 0;
  transition: opacity .15s linear;
}
.kyc-kyb-tabs .tab-pane, .airdrop-tabs .tab-pane {
  display: none;
  padding: 20px 0 0;
}
.kyc-kyb-tabs .tab-pane.active, .airdrop-tabs .tab-pane.active {
  display: block;
}
.kyc-kyb-tabs .fade.show, .airdrop-tabs .fade.show {
  opacity: 1;
}
.kyc-kyb-tabs .nav-tabs .nav-item, .airdrop-tabs .nav-tabs .nav-item {
  display: flex;
  flex-grow: 1;
}
.kyc-kyb-tabs .nav-tabs .nav-item .nav-link, .airdrop-tabs .nav-tabs .nav-item .nav-link {
  /* border-radius: 5px 5px 0 0; */
  font-size: 18px;
  color: #fff;
  padding: 5px 15px;
  width: 90px;
  transition: all ease-in-out 0.2s;
  width: 100%;
  text-align: center;
}
.kyc-kyb-tabs .nav-tabs .nav-item.show .nav-link, .airdrop-tabs .nav-tabs .nav-item.show .nav-link,
.kyc-kyb-tabs .nav-tabs .nav-item .nav-link:hover, .airdrop-tabs .nav-tabs .nav-item .nav-link:hover,
.kyc-kyb-tabs .nav-tabs .nav-link.active, .airdrop-tabs .nav-tabs .nav-link.active {
  background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
}
.kyc-kyb-tabs{
  width: 400px;
  max-width: 100%;
}
.form-wrap .inner .kyc-kyb-tabs .tab-pane > p{
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #A9AAAD;
}